import "../../NewContact/newcontact.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../../../helpers/request";
import PropTypes from "prop-types";

import { Breadcrumbs, Typography } from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { DataGrid } from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import { Grid } from "@mui/material";

import { useAppState } from "../../../../state";

import Swal from "sweetalert2";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const contactType = [
  {
    label: "Phone Number",
    value: "phone",
  },
  {
    label: "SMS",
    value: "sms",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "WhatsApp",
    value: "whatsapp",
  },
  {
    label: "SMS & Email",
    value: "sms_email",
  },
];

const filter = createFilterOptions();

export default function CreateEdit(props) {
  const params = useParams();
  const [data, setData] = useState({
    name: "",
    type: "",
    parent_type: params.hasOwnProperty("parentType") ? params.parentType : "",
    old_parent_type: "",
  });
  const [parentList, setParentList] = useState("");
  const [loading, setLoading] = useState(false);
  const { user: loggedUser } = useAppState();
  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id;
  const listId = params.parentType ? params.parentType : params.listId;
  const location = useLocation();
  const history = useHistory();
  const isParentUpdatePage = location.pathname
    .split("/")
    .includes("edit-contactlist-parent");
  const isEditable = location.pathname.split("/").includes("edit-contact-list");

  //Check contact clone exist in URL
  const isContactClone = location.pathname
    .split("/")
    .includes("contact-list-clone");

  useEffect(() => {
    if ((isParentUpdatePage || isEditable || isContactClone) && listId) {
      getListDetails();
    }
    getParentTypes();
  }, []);

  const getParentTypes = () => {
    let url = `/get-contactlist-parents/all`;
    if (loggedUser.role !== "superadmin") {
      url = "/get-contactlist-parents/" + companyId;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          console.log("get-contactlist-parents/all", res.data);
          let templist = res.data;
          templist = templist.filter((i) => {
            return (
              i.parent_type != "" &&
              i.parent_type != "null" &&
              typeof i.parent_type != undefined &&
              i.parent_type != null
            );
          });
          setParentList(templist);
        }
      })
      .catch((err) => {
        console.error("errr", err);
      });
  };

  const getListDetails = () => {
    axios
      .get(`/contact-list/${listId}`)
      .then((res) => {
        if (res.data && res.data.listId) {
          let allData = res.data;
          allData.old_parent_type = allData.parent_type;
          setData(allData);
        }
      })
      .catch((err) => {
        console.error("errr", err);
      });
  };

  const isValidData = () => {
    const { name, type, parent_type } = data;
    let isValid = true;
    let message = "";

    if (name == "") {
      isValid = false;
      message = "Please enter the list name";
    }

    if (type == "") {
      isValid = false;
      message = "Please select type of contact list";
    }

    if (
      parent_type == "" ||
      parent_type == null ||
      typeof parent_type == undefined
    ) {
      isValid = false;
      message = "Please select parent type of contact list";
    }

    return { isValid, message };
  };

  /*@Usage : To add contact list with contacts*/
  const addContactListWithContact = async (listId, apiParams) => {
    try {
      const payload = {
        listId,
        name: apiParams.name,
        type: apiParams.type,
        parent_type: apiParams.parent_type,
      };
      const res = await axios.post("/add-bulk-contact", payload);
      if (res.data && res.data.affectedRows > 0) {
        await Swal.fire({
          title: "List added successfully",
          confirmButtonColor: "#3085d6",
        });
        history.push(`/view-contact-list/${res.data.newListId}`);
      } else {
        await Swal.fire({
          title: "Something went wrong adding list!",
          confirmButtonColor: "#3085d6",
        });
      }
      setLoading(false);
    } catch (error) {
      await Swal.fire({
        title: "Error in addContactListWithContact",
        confirmButtonColor: "#3085d6",
      });
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const { isValid, message } = isValidData();
    if (isValid) {
      let parentTypeOBj = data.parent_type;
      let apiParams = JSON.parse(JSON.stringify(data));
      let selectedParentType = parentTypeOBj.hasOwnProperty("inputValue")
        ? parentTypeOBj.inputValue
        : parentTypeOBj.parent_type;
      if (selectedParentType !== "" && selectedParentType !== null) {
        apiParams.parent_type = selectedParentType;
      }

      if ((isParentUpdatePage || isEditable || isContactClone) && listId) {
        if (
          !apiParams.hasOwnProperty("parent_type") ||
          apiParams.parent_type == "" ||
          apiParams.parent_type == null ||
          typeof apiParams.parent_type == undefined
        ) {
          apiParams.parent_type = isParentUpdatePage
            ? params.parentType
            : data.old_parent_type;
        }
      }

      if (
        (!apiParams.hasOwnProperty("parent_type") ||
          apiParams.parent_type == "" ||
          apiParams.parent_type == null ||
          typeof apiParams.parent_type == undefined) &&
        params.parentType
      ) {
        apiParams.parent_type = params.parentType;
      }

      //If request comes for clone then only below condition execute
      if (isContactClone) {
        addContactListWithContact(params.listId, apiParams);
        return;
      }

      if (isParentUpdatePage) {
        axios
          .put("update-contactlists-parent", {
            ...apiParams,
          })
          .then(async (res) => {
            if (res.data) {
              await Swal.fire({
                title: "All list updated successfully",
                confirmButtonColor: "#3085d6",
              });
              history.push("/contacts");
            } else {
              await Swal.fire({
                title: "Something went wrong adding list!",
                confirmButtonColor: "#3085d6",
              });
            }
            setLoading(false);
            // console.log(res.data)
          })
          .catch(async (err) => {
            await Swal.fire({
              title:
                err?.response?.data?.message ||
                "Something went wrong adding list!",
              confirmButtonColor: "#3085d6",
            });
            setLoading(false);
            console.error(err);
          });
      } else if (!isEditable) {
        axios
          .post("add-contact-list", {
            ...apiParams,
          })
          .then(async (res) => {
            if (res.data && res.data.affectedRows === 1) {
              await Swal.fire({
                title: "List added successfully",
                confirmButtonColor: "#3085d6",
              });
              history.push(`/view-contact-list/${res.data.insertId}`);
            } else {
              await Swal.fire({
                title: "Something went wrong adding list!",
                confirmButtonColor: "#3085d6",
              });
            }
            setLoading(false);
            // console.log(res.data)
          })
          .catch(async (err) => {
            await Swal.fire({
              title:
                err?.response?.data?.message ||
                "Something went wrong adding list!",
              confirmButtonColor: "#3085d6",
            });
            setLoading(false);
            console.error(err);
          });
      } else {
        axios
          .put(`contact-list/${listId}`, {
            ...apiParams,
          })
          .then(async (res) => {
            if (res.data) {
              // history.push("/contacts")
              console.log("res.data", res.data);
              await Swal.fire({
                title: "List updated successfully",
                confirmButtonColor: "#3085d6",
              });
              props.setCurrentPage("view-list");
            } else {
              await Swal.fire({
                title: "Something went wrong updating list!",
                confirmButtonColor: "#3085d6",
              });
            }
            setLoading(false);
            // console.log(res.data)
          })
          .catch(async (err) => {
            await Swal.fire({
              title:
                err?.response?.data?.message ||
                "Something went wrong updating list!",
              confirmButtonColor: "#3085d6",
            });
            setLoading(false);
            console.error(err);
          });
      }
    } else {
      setLoading(false);
      Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" className="mb-5">
        <Link to="/contacts"> Contact Lists </Link>
        <Typography color="text.primary">
          {isParentUpdatePage
            ? "Update List Parent Type"
            : isEditable
              ? "Edit List"
              : "New List"}
        </Typography>
      </Breadcrumbs>

      <Grid container className="contactListForm">
        {!isParentUpdatePage && (
          <Grid item xs={12} md={4} lg={4}>
            <label>
              Type <span className="mandatory">*</span>
            </label>
            <select
              name="type"
              className="popupDropdown"
              onChange={(e) => {
                setData({
                  ...data,
                  type: e.target.value,
                });
              }}
              value={data.type}
            >
              <option disabled selected value="">
                Select contact list type
              </option>
              {contactType.map((contact) => {
                return (
                  <option key={contact.value} value={contact.value}>
                    {contact.label}
                  </option>
                );
              })}
            </select>
          </Grid>
        )}

        {!isParentUpdatePage && <Grid item xs={12} md={8} lg={8}></Grid>}
        <Grid item xs={12} md={4} lg={4} className="mt-15">
          <Grid item xs={12} md={12} lg={12} className="sendNowlItem">
            <label>
              Parent Type <span className="mandatory">*</span>
            </label>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="sendNowItem">
            <Autocomplete
              value={data.parent_type}
              onChange={(event, newValue) => {
                setData({ ...data, parent_type: newValue });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.parent_type,
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    parent_type: `Add "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={parentList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.parent_type;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.parent_type}</li>
              )}
              sx={{ width: "100%" }}
              freeSolo
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>

        {!isParentUpdatePage && (
          <>
            <Grid item xs={12} md={8} lg={8}></Grid>
            <Grid item xs={12} md={4} lg={4} className="mt-15">
              <Grid item xs={12} md={12} lg={12} className="sendNowlItem">
                <label>
                  List name <span className="mandatory">*</span>
                </label>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className="sendNowItem">
                <input
                  name="label"
                  type="text"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name}
                  placeholder="Enter the name"
                />
              </Grid>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={8} lg={8}></Grid>

        <Grid item xs={12} md={4} lg={4} className="mt-15">
          <button
            disabled={Object.keys(data).filter((v) => !v).length > 0 || loading}
            onClick={handleSubmit}
            className="btn btn-primary text-white br-10 importsubmit"
          >
            {isParentUpdatePage ? "Update" : isEditable ? "Create" : "Save"}
          </button>
        </Grid>
      </Grid>
    </>
  );
}
