import React from "react";
import axios from "../../../helpers/request";
import "./campaigndetail.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
  AddOutlined,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  );
}

export default function ConversationsTable({ campaignid }) {
  const [data, setData] = useState({
    data: [],
    total: 0,
  });

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);

  const getSmsSends = (campaignid) => {
    setData({
      data: [],
      total: 0,
    });
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize,
    };
    axios
      .get("/conversations/" + campaignid, { params: searchParams })
      .then(async (response) => {
        console.log("response", response);
        if (response.status === 200 && response.data) {
          setData(response.data);
        } else {
          throw new Error(response?.error);
        }
      });
  };

  useEffect(() => {
    if (campaignid) {
      getSmsSends(campaignid);
    }
  }, [campaignid, pageSize, pageNumber]);

  const columns = [
    { field: "id", headerName: "ID", width: 50, sortable: false },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      sortable: false,
      valueGetter: ({ value }) => (value == "0" ? "Outgoing" : "Incoming"),
    },
    {
      field: "messagetime",
      headerName: "Date Processed",
      width: 170,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "messagebody",
      headerName: "Message Body",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const text = params.row.messagebody.replace(/\n/g, "");
        console.log("text", text);
        return (
          <span
            className="normal-line-hight"
            dangerouslySetInnerHTML={{ __html: text }}
          ></span>
        );
      },
    },
    { field: "status", headerName: "Status", width: 120, sortable: false },
    {
      field: "processed",
      headerName: "Processed",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => Number(value),
    },
    {
      field: "delivered",
      headerName: "Delivered",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => Number(value),
    },
    {
      field: "read",
      headerName: "Read",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => Number(value),
    },
    {
      field: "failed",
      headerName: "Failed",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => Number(value),
    },
  ];

  return (
    <div className="sentsTable">
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Conversations</h3>
      </div>
      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
