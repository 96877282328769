import "../NewContact/newcontact.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "../../../helpers/request";

import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minwidth: "400px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const filter = createFilterOptions();

export default function AllContacts(props) {
  const [listInfo, setListInfo] = useState({
    name: "",
    type: "",
  });
  const [loading, setLoading] = useState(0);
  const [allContacts, setAllContacts] = useState([]);
  const [allList, setAllList] = useState([]);
  const params = useParams();
  console.log("AllContacts-params", params);
  const location = useLocation();
  const history = useHistory();
  const [selectedList, setSelectedList] = useState({});
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getAllContacts();
  }, []);

  const getAllContacts = () => {
    axios
      .get(`/all-contacts`)
      .then((res) => {
        if (res.data) {
          let allData = res.data;
          let checkdup = [];
          let tempAllList = [];
          allData.map((i) => {
            if (!checkdup.includes(i.listId)) {
              checkdup.push(i.listId);
              tempAllList.push({ list_id: i.listId, list_name: i.list_name });
            }
          });
          console.log("allData => ", allData);
          console.log("tempAllList => ", tempAllList);
          setAllContacts(allData);
          setAllList(tempAllList);
        }
      })
      .catch((err) => {
        console.error("errr", err);
      });
  };

  const handleDelete = async (id, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete this contact`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axios
        .delete("/contact/" + id)
        .then(async function (response) {
          await Swal.fire({
            title: "Contact deleted successfully",
            confirmButtonColor: "#3085d6",
          });
          getAllContacts();
        })
        .catch(async function (error) {
          await Swal.fire({
            title: error?.response?.data?.message || "Unable to delete contact",
            confirmButtonColor: "#3085d6",
          });
        });
    }
  };

  let contactColumns = [
    {
      field: "checkboxes",
      headerName: "Select",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div className="userListActions">
            <input
              type="checkbox"
              id={"row_id_" + row.id}
              onClick={(event) => {
                let tempclist = JSON.parse(JSON.stringify(selectedContactIds));
                if (event.target.checked) {
                  tempclist.push(row.id);
                } else {
                  tempclist = tempclist.filter((e) => e !== row.id);
                }
                setSelectedContactIds(tempclist);
              }}
            />
          </div>
        );
      },
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "first_name", headerName: "First Name", width: 300 },
    { field: "last_name", headerName: "Last Name", width: 250 },
    { field: "number", headerName: "Number", width: 250 },
    { field: "email", headerName: "Email", width: 350 },
    { field: "list_name", headerName: "List Name", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div className="userListActions">
            <Link
              to={{
                pathname: "/contact-edit/" + row.id,
                state: { listId: row.listId },
              }}
            >
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                {/* <span className="actionButtonText">Edit</span> */}
              </button>
            </Link>
            <DeleteOutlined
              className="userListDelete"
              onClick={() =>
                handleDelete(row.id, row.first_name + " " + row.last_name)
              }
            />
          </div>
        );
      },
    },
  ];

  const handleCancelAction = () => {
    setOpenModal(false);
  };

  const handleSubmitAction = async () => {
    if (selectedContactIds.length && selectedList) {
      let apiParams = {};
      apiParams.selectedList = selectedList;
      apiParams.selectedContactIds = selectedContactIds;

      axios
        .put("move-contacts-to-list", {
          ...apiParams,
        })
        .then(async (res) => {
          if (res.data) {
            setOpenModal(false);
            setSelectedContactIds([]);
            setSelectedList({});
            await Swal.fire({
              title: "All contacts moved to list successfully",
              confirmButtonColor: "#3085d6",
            });
            getAllContacts();
          } else {
            await Swal.fire({
              title: "Something went wrong adding list!",
              confirmButtonColor: "#3085d6",
            });
          }
          setLoading(false);
          // console.log(res.data)
        })
        .catch(async (err) => {
          await Swal.fire({
            title:
              err?.response?.data?.message ||
              "Something went wrong adding list!",
            confirmButtonColor: "#3085d6",
          });
          setLoading(false);
          console.error(err);
        });
    } else {
      await Swal.fire({
        title: "Select contact list from options",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  return (
    <div>
      <div className="text-end my-2">
        <button
          onClick={() =>
            selectedContactIds.length
              ? setOpenModal(true)
              : Swal.fire({
                  title: "Please select some contacts.",
                  confirmButtonColor: "#3085d6",
                })
          }
          className="btn btn-primary text-white br-10"
        >
          {selectedContactIds.length
            ? "Create list from selected"
            : "Create List"}
        </button>
        <button
          onClick={() => {
            history.push("/newContact", {
              listId: "all_list",
              type: "sms_email",
            });
          }}
          className="btn btn-primary text-white br-10 ml-15"
        >
          Add Contact
        </button>
      </div>
      <DataGrid
        rows={allContacts}
        columns={contactColumns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
      <BootstrapDialog
        onClose={handleCancelAction}
        aria-labelledby="customized-dialog-title-1"
        open={openModal}
        className="editcolumnModal"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="editcolumnModal22"
        >
          Move selected contacts to selected list
        </DialogTitle>
        <DialogContent dividers>
          <label>Select List</label>
          <Autocomplete
            onChange={(event, newValue) => {
              setSelectedList(newValue);
            }}
            /*filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.list_name);
                                /*if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        list_name: `Add "${inputValue}"`,
                                    });
                                }*
                                return filtered;
                            }}*/
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={allList}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.list_name;
            }}
            renderOption={(props, option) => (
              <li {...props}>{option.list_name}</li>
            )}
            sx={{ width: "100%" }}
            freeSolo
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleSubmitAction}
            className="btn btn-primary text-white br-10"
          >
            Submit
          </button>
          <button
            onClick={handleCancelAction}
            className="btn btn-primary text-white br-10"
          >
            Cancel
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
