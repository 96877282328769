import "./importcontacts.css";
import React, { useEffect } from "react";

import { Grid } from "@mui/material";
//import UploadIcon from '@mui/icons-material/Upload';
import ContactsIcon from "@mui/icons-material/Contacts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UploadIcon from "../../../images/selectmethodupload.svg";
import CopyIcon from "../../../images/selectmethodcopypaste.svg";

export default function SelectMethod(props) {
  useEffect(() => {
    if (props.componentCalledSource !== "contactList") {
      props.setUpdateModalSize(true);
    }
  }, []);

  const clickHandler = (optionclicked) => {
    props.setImportOptionSelected();
    props.continueHandler(optionclicked);
  };

  return (
    <>
      <span className="import-contact-header">
        How would you like to add contacts?
      </span>

      <Grid container spacing={1} className="importOptions">
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
          className={
            props.importOptionSelected === "upload_file"
              ? "import-optiondiv select-method-options checked-import-method"
              : "import-optiondiv select-method-options"
          }
          onClick={() => props.continueHandler("upload_file")}
        >
          <span className="import-contact-p">
            <img src={UploadIcon} title="upload icon" />
            &nbsp;&nbsp;&nbsp;Upload a .csv file
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          lg={3}
          className={
            props.importOptionSelected === "copy_paste_file"
              ? "import-optiondiv select-method-options copypaste-option checked-import-method"
              : "import-optiondiv copypaste-option select-method-options"
          }
          onClick={() => props.continueHandler("copy_paste_file")}
        >
          <span className="import-contact-p">
            <img src={CopyIcon} title="copy paste icon" />
            &nbsp;&nbsp;&nbsp;Copy and paste from excel
          </span>
        </Grid>
        {props.componentCalledSource !== "contactList" &&
        props.enable_contacts ? (
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className={
              props.importOptionSelected === "choose_existing_contact_list"
                ? "import-optiondiv select-method-options copypaste-option checked-import-method"
                : "import-optiondiv copypaste-option select-method-options"
            }
            onClick={() =>
              props.continueHandler("choose_existing_contact_list")
            }
          >
            <span className="import-contact-p">
              <ContactsIcon />
              &nbsp;&nbsp;&nbsp;Existing contact lists
            </span>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
