import React from "react";
import "./custompopup.css";

function CustomPopup(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <button
          id="mypopup"
          className="popupElementButton close-btn"
          onClick={() => props.setTrigger(false)}
        >
          <span className="templateButtonText">X</span>
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default CustomPopup;
