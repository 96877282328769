import "./analytics.css";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import { useState, useEffect } from "react";
import axios from "../../../helpers/request";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { Grid } from "@mui/material";
import { useAppState } from "../../../state";
import Button from "@material-ui/core/Button";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Analytics() {
  const { user: loggedUser, setIsLoading } = useAppState();
  const [data, setData] = useState({ data: [], total: 0 });
  const [analyticsType, setAnalyticsType] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [disableExport, setDisableExport] = useState(false);

  const datacolumns = [
    "id",
    "campaignname",
    "campaigntype",
    "datetimesent",
    "sentby",
    "total",
    "processed",
    "delivered",
    "failed",
    "scheduled",
  ];

  const getAnalytics = () => {
    setData({
      data: [],
      total: 0,
    });
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize,
      analytics_type: analyticsType,
      companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
    };
    if (loggedUser.role === "superadmin") {
      searchParams.companyid = "superadmin";
    }
    setIsLoading(true);
    axios
      .get("/analytics", { params: searchParams })
      .then(async (response) => {
        setIsLoading(false);
        if (response.status === 200 && response.data && response.data.data) {
          setData(response.data);
        } else {
          throw new Error(response?.error);
        }
      })
      .catch(async function (error) {
        setIsLoading(false);
        await Swal.fire({
          title: "Unable to get analytics data",
          confirmButtonColor: "#3085d6",
        });
      });
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      const response = await axios.delete(`/analytic/${campaignId}`);
      if (response.data.deleted) {
        setData({
          data: [],
          total: 0,
        });
        setTimeout(() => {
          getAnalytics();
        }, 100);
        Swal.fire({
          title: "Campaign deleted successfully",
          confirmButtonColor: "#3085d6",
        });
      } else {
        throw Error("Failed to delete campaign");
      }
    } catch (error) {
      Swal.fire({
        title: "Failed to delete campaign",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [pageSize, pageNumber, analyticsType]);

  const handleAnalyticsTypeChange = (event) => {
    console.log("EVE:", event);
    setAnalyticsType(event.target.value);
    setPageNumber(1);
  };

  const objectToCsv = function (data) {
    const csvRows = [];
    csvRows.push(datacolumns.join(","));

    // Loop to get value of each objects key
    for (const row of data) {
      const values = datacolumns.map((header) => {
        let val = row[header];
        if (header === "campaignname") {
          let newVal = val.replace(/,/g, " ");
          return `${newVal}`;
        } else {
          return `${val}`;
        }
      });

      // To add, separator between each value
      csvRows.push(values.join(","));
    }

    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join("\n");
  };

  const download = function (data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" });
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);
    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a");
    // Passing the blob downloading url
    a.setAttribute("href", url);
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", "analytics_" + new Date().getTime() + ".csv");
    // Performing a download with click
    a.click();
  };

  const exportAllCampaigns = () => {
    let searchParams = {
      page_number: -1,
      page_size: -1,
      analytics_type: analyticsType,
      companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
    };
    setDisableExport(true);
    axios.get("/analytics", { params: searchParams }).then(async (response) => {
      // console.log("response", response);
      if (response.status === 200 && response.data) {
        let tempAllData = response.data.map((row) => {
          let camp_type = "";
          switch (row.type) {
            case 11:
              camp_type = "SMS & Email";
              break;
            case 2:
              camp_type = "WhatsApp";
              break;
            case 1:
              camp_type = "Email";
              break;
            default:
              camp_type = "SMS";
              break;
          }

          let tempTotal =
            row.type === 2 ? row.totalwpmessages : row.totalmessages;
          let tempProcessed =
            Number(row.processedEmail) +
            Number(row.processedSMS) +
            Number(row.processedWP);
          let tempDelivered =
            Number(row.deliveredEmail) +
            Number(row.deliveredSMS) +
            Number(row.deliveredWP);
          let tempBounced =
            Number(row.bouncedEmail) +
            Number(row.droppedEmail) +
            Number(row.failedSMS) +
            Number(row.failedWP);
          let tempScheduled = row.scheduled ? "Yes" : "No";

          return {
            id: row.id,
            campaignname: row.campaignname,
            campaigntype: camp_type,
            datetimesent: row.datetimesent,
            sentby: row.sentby,
            total: tempTotal,
            processed: tempProcessed,
            delivered: tempDelivered,
            failed: tempBounced,
            scheduled: tempScheduled,
          };
        });
        const content = objectToCsv(tempAllData);
        download(content);
        setDisableExport(false);
      } else {
        setDisableExport(false);
        throw new Error(response?.error);
      }
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 80, sortable: false },
    {
      field: "campaignname",
      headerName: "Campaign Name",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Campaign Type",
      minWidth: 150,
      flex: 0.4,
      sortable: false,
      renderCell: (params) => {
        switch (params.row.type) {
          case 11:
            return (
              <>
                <Chip size="small" label="SMS" />
                <Chip size="small" label="Email" />
              </>
            );
          case 2:
            return <Chip size="small" label="WhatsApp" />;
          case 1:
            return <Chip size="small" label="Email" />;
          default:
            return <Chip size="small" label="SMS" />;
        }
      },
    },
    {
      field: "datetimesent",
      headerName: "Date Sent",
      width: 170,
      type: "dateTime",
      valueGetter: ({ value }) =>
        value && moment(value).format("DD/MM/YYYY kk:mm"),
    },
    { field: "sentby", headerName: "Sent By", width: 140, sortable: false },
    {
      field: "totalmessages",
      headerName: "Total",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.type === 2
              ? params.row.totalwpmessages
              : params.row.totalmessages}
          </>
        );
      },
    },
    {
      field: "processedEmail",
      headerName: "Processed",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.processedEmail) +
              Number(params.row.processedSMS) +
              Number(params.row.processedWP)}
          </>
        );
      },
    },
    {
      field: "deliveredEmail",
      headerName: "Delivered",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.deliveredEmail) +
              Number(params.row.deliveredSMS) +
              Number(params.row.deliveredWP)}
          </>
        );
      },
    },
    {
      field: "bouncedEmail",
      headerName: "Failed",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {Number(params.row.bouncedEmail) +
              Number(params.row.droppedEmail) +
              Number(params.row.failedSMS) +
              Number(params.row.failedWP)}
          </>
        );
      },
    },
    {
      field: "scheduled",
      headerName: "Scheduled",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.scheduled ? "Yes" : "No"}</div>;
      },
    },
    // ...(['', '1', '11'].includes(analyticsType)
    //   ? [
    //       {
    //         field: 'openedEmail',
    //         headerName: 'Opened Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.openedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'clickedEmail',
    //         headerName: 'Clicked Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.clickedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'uniqueOpenedEmail',
    //         headerName: 'Unique Opened Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.uniqueOpenedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'uniqueClickedEmail',
    //         headerName: 'Unique Clicked Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.uniqueClickedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'bouncedEmail',
    //         headerName: 'Bounced Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.bouncedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'droppedEmail',
    //         headerName: 'Dropped Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.droppedEmail : 'NA'}</div>
    //         }
    //       },
    //       {
    //         field: 'invalidEmail',
    //         headerName: 'Invalid Emails',
    //         minWidth: 150,
    //         flex: 0.3,
    //         sortable: false,
    //         renderCell: params => {
    //           return <div>{[1, 11].includes(params.row.type) ? params.row.invalidEmail : 'NA'}</div>
    //         }
    //       }
    //     ]
    //   : []),
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      flex: 0.6,
      sortable: false,
      renderCell: (params) => {
        function isFutureBy5Minutes(timestamp) {
          const currentDate = new Date();
          const givenDate = new Date(timestamp);
          const futureDate = new Date(currentDate.getTime() + 5 * 60000);
          return givenDate > futureDate;
        }
        function isOldCampaign(timestamp) {
          return new Date(timestamp) < new Date();
        }
        return (
          <div className="userListActions">
            <Link to={"/campaign-detail/" + params.row.campaignid}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            {!!params.row.scheduled &&
              params.row.dateToSendTimestamp &&
              isFutureBy5Minutes(params.row.dateToSendTimestamp * 1000) && (
                <>
                  <Link
                    to={
                      (params.row.type === 0
                        ? "/edit-sms-schedule/"
                        : "/edit-schedule/") + params.row.campaignid
                    }
                  >
                    <button className="actionElementButton">
                      <EditOutlined className="actionElementIcon" />
                      <span className="actionButtonText">Edit</span>
                    </button>
                  </Link>
                </>
              )}
            {((params.row.dateToSendTimestamp &&
              isOldCampaign(params.row.dateToSendTimestamp)) ||
              !params.row.dateToSendTimestamp) && (
              <button
                className="actionElementButton"
                onClick={() => handleDeleteCampaign(params.row.campaignid)}
              >
                <DeleteOutlined className="actionElementIcon" />
                <span className="actionButtonText">Delete</span>
              </button>
            )}

            {/* Clone of campaign start */}
            {params.row.sms_channel_id || params.row.email_channel_id ? (
              <>
                <Link to={`/campaign-clone/${params.row.campaignid}`}>
                  <button className="actionElementButton">
                    <ContentCopyIcon className="actionElementIcon" />
                    <span className="actionButtonText">Clone</span>
                  </button>
                </Link>
              </>
            ) : null}
            {/* Clone of campaign end */}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Grid container spacing={2} className="ptb-0 main-grid-container">
        <Grid item xs={12} md={3} lg={3}>
          <div className="sendNowItem">
            <label>Campaign Type</label>
            <select
              name="sendGridSenderId"
              className="popupDropdown"
              value={analyticsType}
              onChange={(event) => handleAnalyticsTypeChange(event)}
            >
              <option value="">All</option>
              <option value="11">SMS and Email</option>
              <option value="2">WhatsApp</option>
              <option value="1">Email</option>
              <option value="0">SMS</option>
            </select>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid item xs={12} md={3} lg={3}>
          {/* <CSVLink
            data={allData}
            filename={"analytics.csv"}
            className="btn btn-primary text-white br-10 rightside-btn"
            target="_blank"
          >
            Export
          </CSVLink> */}
        </Grid>
      </Grid>

      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => setPageNumber(newPage + 1)}
        disableSelectionOnClick
        paginationMode="server"
        autoHeight={true}
        components={{
          ColumnMenuIcon: FilterAltOutlinedIcon,
          Toolbar: () => {
            return (
              <div className="tableButtonRow">
                <span className="exportbutton">
                  <Button
                    disabled={disableExport}
                    className="exportbutton_other"
                    color="primary"
                    onClick={exportAllCampaigns}
                  >
                    Export All
                  </Button>
                </span>
              </div>
            );
          },
        }}
      />
    </div>
  );
}
