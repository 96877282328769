export const getFileExtension = (fileName) => {
  return fileName?.split(".").pop() || "";
};

export const filterEmailContent = (ckcontent) => {
  ckcontent = new DOMParser().parseFromString(ckcontent, "text/html");
  ckcontent
    .querySelectorAll(".ck-widget__resizer")
    .forEach((el) => el.remove());
  ckcontent.querySelectorAll(".ck-reset_all").forEach((el) => el.remove());
  ckcontent
    .querySelectorAll(".ck-widget__selection-handle")
    .forEach((el) => el.remove());
  ckcontent.querySelectorAll(".ck-reset_all").forEach((el) => el.remove());
  ckcontent
    .querySelectorAll(".ck-fake-selection-container")
    .forEach((el) => el.remove());
  // const finalContent = juice(`${ckstyles} ${ckcontent.documentElement.innerHTML}`)
  // return finalContent;
  return ckcontent.documentElement.innerHTML;
};
