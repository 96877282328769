import {
  LayersOutlined,
  EditOutlined,
  ArrowBack,
  ArrowBackIosOutlined,
  KeyboardReturnOutlined,
} from "@material-ui/icons";
import "./template.css";
import { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "../../../helpers/request";
import { useAppState } from "../../../state";

//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
//import CustomImageUploadAdapter from "../../common/ckeditorImageUploadAdaptor";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { ckeditorConfig } from "../../common/ckeditorConfig";
import Swal from "sweetalert2";

const TEMPLATE_TYPE_EMAIL_VALUE = "email";

export default function Template() {
  const { templateId } = useParams();
  const location = useLocation();
  const isEditable = location.pathname.split("/").includes("template-edit");
  const [adding, setAdding] = useState(false);
  const { user } = useAppState();

  const [template, setTemplate] = useState({
    templatename: "",
    templatebody: "",
    updateBy: user.fullName,
    type: null,
  });
  const [emailEditor, setEmailEditor] = useState("");

  const loadCKEditor = () => {
    if (window && document) {
      let ckEditorDiv = document.getElementsByClassName("ck-editor");
      if (!ckEditorDiv.length) {
        window.CKEDITOR.ClassicEditor.create(
          document.getElementById("super-ckeditor"),
          ckeditorConfig,
        )
          .then((editor) => {
            window.editor = editor;
            setEmailEditor(editor);
            editor.setData(template.templatebody);
            if (!isEditable) {
              document.getElementsByClassName(
                "ck-editor",
              )[0].style.pointerEvents = "none";
              var el = document.querySelectorAll(
                ".ck-widget__type-around__button",
              );
              for (var i = 0; i < el.length; i++) {
                el[i].style.display = "none";
              }
            }
          })
          .catch((error) => {
            console.log("error of ckeditor create=>", error);
            loadCKEditor();
          });
      }
    }
  };

  useEffect(() => {
    if (template.type === "email") {
      loadCKEditor();
    }
  }, [template.templatebody]);

  const getTemplates = () => {
    axios.get("/templates").then(async (response) => {
      if (response.status === 200 && response.data) {
        const selectedTemplate = response.data.filter(
          (temp) => temp.id == templateId,
        );

        if (selectedTemplate && selectedTemplate[0]) {
          // setTemplate(selectedTemplate[0]);
          setTemplate((prevTemplate) => ({
            ...prevTemplate,
            templatename: selectedTemplate[0].templatename,
            templatebody: selectedTemplate[0].templatebody,
            type: selectedTemplate[0].type,
          }));
          console.log("api response got...");
        }
      } else {
        throw new Error(response?.error);
      }
    });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const isValidData = () => {
    const { templatename, templatebody } = template;

    let isValid = true;
    let message = "";

    if (templatename === "" || templatebody === "") {
      isValid = false;
      message = "All fields are mandatory";
    }
    return { isValid, message };
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let ckcontent = "";
    if (template.type === "email") {
      ckcontent = document.getElementsByClassName("ck-content")[0].innerHTML;
      let ckcontentLength =
        document.getElementsByClassName("ck-content")[0].children.length;
      if (ckcontentLength) {
        template.templatebody = ckcontent;
      } else {
        template.templatebody = "";
      }
    }

    const { isValid, message } = isValidData();
    if (isValid) {
      if (template.type === "email") {
        ckcontent = new DOMParser().parseFromString(ckcontent, "text/html");
        ckcontent
          .querySelectorAll(".ck-widget__resizer")
          .forEach((el) => el.remove());
        ckcontent
          .querySelectorAll(".ck-reset_all")
          .forEach((el) => el.remove());
        ckcontent
          .querySelectorAll(".ck-widget__selection-handle")
          .forEach((el) => el.remove());
        template.templatebody = ckcontent.documentElement.innerHTML;
      }

      setAdding(true);
      axios
        .put(process.env.REACT_APP_API_BASEURL + "/template/" + templateId, {
          data: template,
        })
        .then(function (response) {
          setAdding(false);
          getTemplates(templateId);
          Swal.fire({
            title: "Template updated successfully",
            confirmButtonColor: "#3085d6",
          });
        })
        .catch(function (error) {
          setAdding(false);
          Swal.fire({
            title:
              error?.response?.data?.message ||
              "Unable to process this request",
            confirmButtonColor: "#3085d6",
          });
        });
    } else {
      Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }));
  };

  return (
    <div className="template">
      <div className="pageHeader">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/templates">Templates</Link>
          <Typography color="text.primary">
            {!isEditable ? "View Template" : "Edit Template"}
          </Typography>
        </Breadcrumbs>
      </div>
      <form className="sendNowForm">
        <div className="sendNowItem">
          <label>
            Template Name<span className="mandatory">*</span>
          </label>
          <input
            disabled={!isEditable || adding}
            type="text"
            placeholder="Give your template a unique name"
            name="templatename"
            value={template.templatename}
            onChange={handleChange}
          />
        </div>
        {template.type && template.type === TEMPLATE_TYPE_EMAIL_VALUE ? (
          <div className="sendNowItem">
            <label>
              Enter Email Body Text <span className="mandatory">*</span>
            </label>
            <div id="super-ckeditor"></div>
          </div>
        ) : (
          <div className="sendNowItem">
            <label>
              Enter SMS Body Text <span className="mandatory">*</span>
            </label>
            <textarea
              disabled={!isEditable || adding}
              placeholder="Enter the body of your sms here"
              name="templatebody"
              value={template.templatebody}
              onChange={handleChange}
            ></textarea>
          </div>
        )}
        <div className="sendNowItem">
          {isEditable ? (
            <button
              disabled={adding}
              onClick={handleSubmit}
              className="newUserButton"
            >
              Update
            </button>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
}
