import "../../NewContact/newcontact.css";
import React, { useState } from "react";

import CreateEdit from "./createEdit";

export default function CreateContactListClone() {
  const [currentPage, setCurrentPage] = useState("create-list");

  return (
    <>
      <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>
  );
}
