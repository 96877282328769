import "./Channels.css";
import { DataGrid } from "@material-ui/data-grid";
import {
  AddOutlined,
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { useState, useEffect } from "react";
import axios from "../../../helpers/request";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

export default function Channels() {
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");
  const getChannels = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          axios
            .get(
              `/all-channels/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`,
            )
            .then(async (response) => {
              if (response.status === 200 && response.data) {
                console.log(response.data);
                setData(response.data);
              } else {
                throw new Error(response?.error);
              }
            });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    getChannels();
  }, []);

  const handleDelete = async (id, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${label}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // setData(data.filter((item) => item.id !== id));
    // const confirmDelete = window.confirm(`Are you sure to delete ${label}`);
    if (result.isConfirmed) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + "/channel/" + id)
        .then(async function (response) {
          getChannels();
          await Swal.fire({
            title: "Channel deleted successfully",
            confirmButtonColor: "#3085d6",
          });
        })
        .catch(async function (error) {
          await Swal.fire({
            title: error?.response?.data?.message || "Unable to delete channel",
            confirmButtonColor: "#3085d6",
          });
        });
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "label", headerName: "Label", width: 200 },
    { field: "sid", headerName: "Account SID", width: 200 },
    { field: "token", headerName: "Auth Token", width: 200 },
    { field: "type", headerName: "Channel Type", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="userListActions">
            <Link to={"/channel-view/" + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={"/channel-edit/" + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>
            <DeleteOutlined
              className="userListDelete"
              onClick={() => handleDelete(params.row.id, params.row.label)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to="/newChannel">
            <button className="btn btn-primary text-white br-10">Add</button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  );
}
