import { useState, useRef, useEffect } from "react";
import csv from "jquery-csv";
import { useParams, useHistory } from "react-router-dom";

import axios from "../../../helpers/request";
import "./cloneSMSEmailCampaign.css";

import Switch from "@material-ui/core/Switch";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CheckIcon from "@mui/icons-material/Check";

import CustomPopup from "../../Popup/CustomPopup";
import { useAppState } from "../../../state";
import templatesIcon from "../../images/templates.svg";
import { filterEmailContent } from "../../common/functions";
import { ckeditorConfig } from "../../common/ckeditorConfig";
import Importcontacts from "../Contacts/Import/ImportContacts";
import PreviewMessage from "../PreviewMessage/PreviewMessage";
import Swal from "sweetalert2";

const SEND_THROUGH_EMAIL_VALUE = "email";

const PHONE_FIELD = "Contact Number";
const PHONE_NUMBER_REGEX = new RegExp(/^\+[\d]+$/);

const EMAIL_FIELD = "Email";
const FIRST_NAME_FIELD = "First Name";
const LAST_NAME_FIELD = "Last Name";
const EMAIL_REGEX = new RegExp(/^\S+@\S+\.\S+$/);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minwidth: "400px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const greetings = [
  { label: "No Greetings", value: "Nogreet" },
  { label: "Dear @FirstName", value: "greetDearFirst" },
  { label: "Dear @FirstName @LastName", value: "greetDearFirstLast" },
  { label: "Hi @FirstName", value: "greetHiFirst" },
  { label: "Hi @FirstName @LastName", value: "greetHiFirstLast" },
  { label: "Hello @FirstName", value: "greetHelloFirst" },
  { label: "Hello @FirstName @LastName", value: "greetHelloFirstLast" },
];

const GREET_DEFAULT_VALUE = greetings[0].value;

export default function CloneSMSEmailCampaign() {
  const fileInputRef = useRef();
  const params = useParams();
  const history = useHistory();

  const [campaign, setCampaign] = useState({
    isBulk: false,
    isBoth: false,
    campaignName: "",
    contactNumber: "",
    smsMessageBody: "",
    email: "",
    messageBody: "",
    greetingText: GREET_DEFAULT_VALUE,
    file: [],
    emailChannelId: "",
    fromEmail: "",
    fromName: "",
    smsChannelId: "",
    smsChannelName: "",
    emailChannelName: "",
    emailMessageBody: "",
  });
  // const [channel, setChannel] = useState("");
  // const [smsChannel, setSmsChannel] = useState("");
  const [contactList, setContactList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const [buttonPopup, setButtonPopup] = useState(false);
  const [smsButtonPopup, setSmsButtonPopup] = useState(false);
  const [sending, setSending] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState("");
  const [smsTemplate, setSmsTemplate] = useState("");
  const [opencontactModal, setOpencontactModal] = useState(false);
  const [updateModalSize, setUpdateModalSize] = useState(false);
  const [emailEditor, setEmailEditor] = useState("");
  const { user: loggedUser } = useAppState();
  const [userSettings, setUserSettings] = useState(false);
  const [isEmailTemplateApplied, setIsEmailTemplateApplied] = useState(false);
  const [isSMSTemplateApplied, setIsSMSTemplateApplied] = useState(false);
  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id;

  const [emailSenderList, setEmailSenderList] = useState([]);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewCampaignData, setPreviewCampaignData] = useState({});

  useEffect(() => {
    if (openPreviewModal) {
      let campaignData = {
        ...campaign,
        emailMessageBody: campaign.messageBody
          ? filterEmailContent(campaign.messageBody)
          : "",
        companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
      };

      setPreviewCampaignData(campaignData);
    } else {
      // setPreviewCampaignData({})
    }
  }, [openPreviewModal]);

  const previewMessage = async () => {
    if (campaign.isBoth && campaign.emailChannelId) {
      campaign.messageBody = emailEditor.getData().trim();
    }

    const { isValid, message, errors } = isValidData();

    if (isValid) {
      setOpenPreviewModal(true);
    } else {
      let errMsg = "Followings are errors in the form \n";
      errors.map((er) => {
        errMsg += "     - " + er + "\n";
      });
      await Swal.fire({ title: errMsg, confirmButtonColor: "#3085d6" });
    }
  };

  const getSettings = () => {
    axios.get(`/get-settings/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        let settings = response.data;
        setUserSettings(settings);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  // const getChannelList = (companyId) => {
  //   axios.get(`/all-channels/${companyId}`).then(async (response) => {
  //     if (response.status === 200 && response.data) {
  //       console.log("all-channels API ", response.data);
  //       setChannelList(response.data);
  //     } else {
  //       throw new Error(response?.error);
  //     }
  //   });
  // };
  const fetchChannelDetail = (channel_id) => {
    axios.get(`/channel/${channel_id}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log("Channel Details", response.data);
        setCampaign((prevState) => {
          return {
            ...prevState,
            smsChannelName: response.data.label,
          };
        });
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const fetchMultipleChannelDetails = async (ids) => {
    try {
      const result = await axios.post("/multiple-channel-details", { id: ids });
      console.log("result", result);
      let smsChannelName = result.data.filter(
        (data) => data.type === "twilio-sms",
      );
      let emailChannelName = result.data.filter(
        (data) => data.type === "sendgrid-mail-service",
      );
      setCampaign((prevState) => {
        return {
          ...prevState,
          smsChannelName: smsChannelName[0].label,
          emailChannelName: emailChannelName[0].label,
        };
      });
    } catch (error) {
      console.log(`Error in catch block multiple channel`, error.message);
      throw new Error(error?.message);
    }
  };

  const getContactList = (companyId) => {
    axios.get(`/all-contacts/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log(response.data);
        setContactList(response.data);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const loadCKEditor = () => {
    if (window && document) {
      let ckEditorDiv = document.getElementsByClassName("ck-editor");
      if (!ckEditorDiv.length) {
        window.CKEDITOR.ClassicEditor.create(
          document.getElementById("super-ckeditor"),
          ckeditorConfig,
        )
          .then((editor) => {
            window.editor = editor;
            setEmailEditor(editor);
            editor.setData(campaign.messageBody);
            editor.enableReadOnlyMode("ck-editor");
            editor.isReadOnly = true;
          })
          .catch((error) => {
            console.log("error of ckeditor create=>", error);
            loadCKEditor();
          });
      }
    }
  };

  // if (campaign.isBoth && smsChannel && channel) {
  // if (campaign.isBoth && campaign.smsChannelId && campaign.emailChannelId) {
  //     loadCKEditor();
  // }
  if (campaign.emailChannelId) {
    loadCKEditor();
  }

  useEffect(() => {
    getSettings();
    axios
      .post(`/userdetails`)
      .then((res) => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by
            ? res.data[0].added_by
            : res.data[0].id;
          // fetchChannelDetail(cmpny);
          getContactList(cmpny);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, []);

  const getSelectedSender = (senders) => {
    let currentUserObj = senders.find(
      (obj) => obj.from_email === loggedUser.email,
    );
    let defaultSenderObj = senders.find((obj) =>
      obj.from_email.toLowerCase().includes("noreply"),
    );

    if (currentUserObj) {
      return currentUserObj;
    } else if (defaultSenderObj) {
      return defaultSenderObj;
    } else {
      return null;
    }
  };

  const openPopup = (event, smsTemp) => {
    event.preventDefault();
    setData([]);
    setTemplate("");
    getTemplates();
    if (smsTemp) {
      setSmsButtonPopup(true);
    } else {
      setButtonPopup(true);
    }
  };

  const getTemplates = () => {
    let queryParams = {
      companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
    };
    axios.get("/templates", { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        const templates = response.data;
        setData(templates);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "fromEmail") {
      const sgSenderObj = emailSenderList.find(
        (obj) => obj.from_email == value,
      );

      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [name]: value,
        fromName: sgSenderObj.from_name,
      }));
    } else {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [name]: value,
      }));
    }
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    if (name == "isBoth") {
      if (checked) {
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          [name]: checked,
        }));
      } else {
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          emailChannelId: "",
          fromEmail: "",
          fromName: "",
          [name]: checked,
        }));
      }
    } else {
      if (checked) {
        setOpencontactModal(true);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          contactNumber: "",
          email: "",
          [name]: checked,
        }));
      } else {
        setSelectedContactList([]);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          [name]: checked,
        }));
      }
    }
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if (
      file &&
      ["csv"].indexOf(file.name.split(".").pop().toLowerCase()) > -1
    ) {
      try {
        const keys_to_keep = [PHONE_FIELD];
        if (campaign.isBoth) {
          keys_to_keep.concat([EMAIL_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD]);
        }

        reader.onloadend = function (event) {
          const csvData = event.target.result.replace(/"/g, "");
          const fileData = csv.toObjects(csvData);

          fileData.map((row) => {
            Object.keys(row).forEach((key) => {
              if (!keys_to_keep.includes(key)) {
                delete row[key];
              }
            });
            return row;
          });

          fileData.map((field) => {
            if (field[PHONE_FIELD] && !field[PHONE_FIELD].startsWith("+")) {
              field[PHONE_FIELD] = "+" + field[PHONE_FIELD];
            }
            return field;
          });

          const filteredFileData = fileData.filter(
            (value, index, self) =>
              value[EMAIL_FIELD] &&
              index ===
                self.findIndex((t) => t[EMAIL_FIELD] === value[EMAIL_FIELD]),
          );

          setCampaign((prevCampaign) => ({
            ...prevCampaign,
            file: filteredFileData,
          }));
        };
        reader.readAsText(file);
      } catch (e) {
        console.log("ERROR-FILE--", e);
      }
    } else {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        file: [],
      }));
      fileInputRef.current.value = "";
      await Swal.fire({
        title: "Only CSV file allowed",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const applyTemplate = (e, smsTemp) => {
    const selectedTemplate = data.filter(
      (temp) => temp.id == (smsTemp ? smsTemplate : template),
    );
    if (selectedTemplate && selectedTemplate[0]) {
      if (smsTemp) {
        setIsSMSTemplateApplied(true);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          smsMessageBody: selectedTemplate[0].templatebody,
        }));
      } else {
        setIsEmailTemplateApplied(true);
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          messageBody: selectedTemplate[0].templatebody,
        }));
        emailEditor.setData(selectedTemplate[0].templatebody);
        if (!userSettings.enable_email_template_edit) {
          document.getElementsByClassName("ck-editor")[0].style.pointerEvents =
            "none";
          var el = document.querySelectorAll(".ck-widget__type-around__button");
          for (var i = 0; i < el.length; i++) {
            el[i].style.display = "none";
          }
        }
      }
    }

    setButtonPopup(false);
    setSmsButtonPopup(false);
  };

  const removeAppliedTemplate = (type) => {
    if (type == "sms") {
      setIsSMSTemplateApplied(false);
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        smsMessageBody: "",
      }));
    } else {
      setIsEmailTemplateApplied(false);
      emailEditor.setData("");
      document.getElementsByClassName("ck-editor")[0].style.pointerEvents =
        "auto";
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        messageBody: "",
      }));
    }
  };

  const resetForm = () => {
    if (emailEditor) {
      emailEditor.setData("");
      document.getElementsByClassName("ck-editor")[0].style.pointerEvents =
        "auto";
    }
    setIsSMSTemplateApplied(false);
    setIsEmailTemplateApplied(false);

    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      campaignName: "",
      contactNumber: "",
      messageBody: "",
      smsMessageBody: "",
      file: [],
      isBulk: false,
      isBoth: false,
      greetingText: GREET_DEFAULT_VALUE,
      email: "",
      emailChannelId: "",
      fromEmail: "",
      fromName: "",
    }));

    setSelectedContactList([]);

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isValidData = () => {
    const {
      campaignName,
      contactNumber,
      isBulk,
      isBoth,
      messageBody,
      smsMessageBody,
      email,
      emailChannelId,
      fromEmail,
      smsChannelId,
    } = campaign;
    let isValid = true;
    let message = "";
    let errors = [];

    if (smsChannelId === "" && emailChannelId === "") {
      isValid = false;
      errors[errors.length] = "Please select SMS channel";
    }

    if (smsChannelId) {
      if (campaignName === "") {
        isValid = false;
        errors[errors.length] = "Campaign name is required";
      }

      if (isBulk) {
        if (selectedContactList.length === 0) {
          isValid = false;
          errors[errors.length] = "Please upload contacts";
        }
      } else {
        if (contactNumber === "") {
          isValid = false;
          errors[errors.length] = "Please enter the contact number";
        } else if (!PHONE_NUMBER_REGEX.test(contactNumber)) {
          isValid = false;
          errors[errors.length] = "Invalid phone number";
        }
      }

      if (smsMessageBody === "") {
        isValid = false;
        errors[errors.length] = "SMS body is required";
      }

      /*if (!isSMSTemplateApplied) {
              isValid = false;
              errors[errors.length] = "SMS template is required";
            }*/
    }

    if (isBoth) {
      if (emailChannelId === "") {
        isValid = false;
        errors[errors.length] = "Please select email channel";
      } else {
        if (!isBulk) {
          if (email === "") {
            isValid = false;
            errors[errors.length] = "Please enter the email";
          } else if (!EMAIL_REGEX.test(email)) {
            isValid = false;
            errors[errors.length] = "Enter valid email";
          }
        }

        if (!fromEmail) {
          isValid = false;
          errors[errors.length] = "Sender is required";
        }

        if (messageBody === "") {
          isValid = false;
          errors[errors.length] = "Email body is required";
        }

        /*if (!isEmailTemplateApplied) {
                  isValid = false;
                  errors[errors.length] = "Email template is required";
                }*/
      }
    }

    return { isValid, message, errors };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (campaign.isBoth && campaign.smsChannelId && campaign.emailChannelId) {
      campaign.messageBody = emailEditor.getData().trim();
    }
    const { isValid, message, errors } = isValidData();

    if (isValid) {
      // if (campaign.isBoth && smsChannel && channel) {
      if (campaign.isBoth && campaign.smsChannelId && campaign.emailChannelId) {
        campaign.messageBody = filterEmailContent(campaign.messageBody);
      }

      setSending(true);

      // const currentSmsChannel = channelList.filter((v) => v.id == smsChannel);
      // const currentSmsChannelData = currentSmsChannel.length ? currentSmsChannel[0] : null;
      // const { fromdata: fromNumber, sid: smsAccountSID, token: smsAuthToken } = currentSmsChannelData;

      // let fromEmail, fromName, emailToken;
      // if (campaign.isBoth) {
      //   const currentEmailChannel = channelList.filter((v) => v.id == channel);
      //   const currentEmailChannelData = currentEmailChannel.length ? currentEmailChannel[0] : null;
      //   if (currentEmailChannelData) {
      //     const { fromdata, label, token } = currentEmailChannelData;
      //     fromEmail = fromdata;
      //     fromName = label;
      //     emailToken = token;
      //   }
      // }
      // fromNumber,
      // smsAccountSID,
      // smsAuthToken,
      // fromEmail,
      // fromName,
      // emailToken,

      // console.log(currentChannelData, currentSmsChannelData);
      console.log("campaign", campaign);
      console.log("selectedContactList", selectedContactList);

      axios
        .post("/send-sms-email-channel", {
          data: {
            ...campaign,
            emailMessageBody: campaign.messageBody,
            contactList: selectedContactList,
            companyid: loggedUser.added_by
              ? loggedUser.added_by
              : loggedUser.id,
          },
          actionBy: loggedUser.fullName,
        })
        .then(async function (response) {
          setSending(false);
          let msg = campaign.emailChannelId === "" ? "SMS" : "emails";
          // alert(
          //     `Sent SMS${campaign.isBoth ? " and emails" : ""} successfully`
          // );
          await Swal.fire({
            title: `Sent ${msg} successfully`,
            confirmButtonColor: "#3085d6",
          });
          resetForm();
          history.push("/analytics");
        })
        .catch(async function (error) {
          setSending(false);
          console.log(error);
          await Swal.fire({
            title: "Unable to process this request",
            confirmButtonColor: "#3085d6",
          });
        });

      // if (campaign.isBoth ? currentChannelData && currentSmsChannelData : currentSmsChannelData) {
      //   currentChannelData = currentChannelData ? currentChannelData : {};

      //   const { fromdata, label, token } = currentChannelData;
      //   Promise.all([
      //     axios.post("/send-message-channel", {
      //       data: {
      //         ...campaign,
      //         messageBody: campaign.smsMessageBody,
      //         contactList: selectedContactList,
      //         fromdata: smsFromData,
      //         sid,
      //         token: smsToken,
      //         companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
      //       },
      //       actionBy: loggedUser.fullName,
      //     }),
      //     campaign.isBoth
      //       ? axios.post("/send-email-channel", {
      //         data: {
      //           ...campaign,
      //           contactList: selectedContactList,
      //           fromdata,
      //           token,
      //           label,
      //           companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
      //         },
      //         actionBy: loggedUser.fullName,
      //       })
      //       : Promise.resolve(),
      //   ])
      //     .then((res) => {
      //       setSending(false);
      //       resetForm();
      //       alert(
      //         `Sent SMS${campaign.isBoth ? " and emails" : ""} successfully`
      //       );
      //     })
      //     .catch(function (error) {
      //       setSending(false);
      //       alert("Unable to process this request");
      //     });
      // }
    } else {
      let errMsg = "Followings are errors in the form \n";
      errors.map((er) => {
        errMsg += "     - " + er + "\n";
      });
      await Swal.fire({ title: errMsg, confirmButtonColor: "#3085d6" });
    }
  };

  const handleImportModalclose = () => {
    if (!selectedContactList.length) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        ["isBulk"]: false,
      }));
    }
    setOpencontactModal(false);
  };

  const getUploadedContactData = (dataArray) => {
    setOpencontactModal(false);
    console.log("dataArray", dataArray);
    setSelectedContactList(dataArray);
  };

  /*@Usage : To fetch channel details*/
  const getAnalyticsDetails = async (campaignid) => {
    try {
      const apiPath = `/get-analytic-details/${campaignid}`;
      const result = await axios.get(apiPath);
      const data = result.data[0];
      console.log("data", data);

      const checkIsEmailBodyExist = data.smsbody.includes("##EMAIL_BODY##");
      const checkIsSMSBodyExist = data.smsbody.includes("##MESSAGE_TEXT##");
      let emailBody,
        smsBody,
        messageBody = "";
      if (checkIsEmailBodyExist && checkIsSMSBodyExist) {
        smsBody = data.smsbody.match(
          /##MESSAGE_TEXT## : (.*?) ##EMAIL_BODY##/,
        )[1];
        emailBody = data.smsbody.match(/##EMAIL_BODY## : (.*?)$/)[1];
      } else {
        messageBody = data?.smsbody.split(":")[1].trim();
      }

      setCampaign((prevState) => {
        return {
          ...prevState,
          campaignName: data.campaignname,
          smsMessageBody: messageBody == "" ? smsBody : messageBody,
          // smsMessageBody: data?.smsbody.split(":")[1].trim(),
          smsChannelId:
            data.type === 0 || data.type === 11 ? data.sms_channel_id : "",
          emailChannelId:
            data.type === 1 || data.type === 11 ? data.email_channel_id : "",
          fromEmail: data.email_from,
          fromName: data.email_from,
          messageBody: messageBody == "" ? emailBody : messageBody,
          isBoth: data.type === 11 ? true : false,
        };
      });
      if (data.type === 11) {
        let ids = `${data.sms_channel_id}, ${data.email_channel_id}`;
        fetchMultipleChannelDetails(ids);
      } else {
        let channel_id =
          data.sms_channel_id == ""
            ? data.email_channel_id
            : data.sms_channel_id;
        fetchChannelDetail(channel_id);
      }
    } catch (error) {
      console.log(`Error in catch block getChannelDetails()`, error.message);
      throw new Error("Error in fetching channel details");
    }
  };
  useEffect(() => {
    console.log("Called Effect", campaign);
    getAnalyticsDetails(params.campaignid);
  }, []);
  return (
    <div className="mainContentWrapper">
      <Grid container spacing={2} className="ptb-0 main-grid-container">
        <Grid container item xs={12} md={12} lg={12} spacing={2}>
          <Grid
            item
            xs={12}
            md={campaign.emailChannelId ? 4 : 6}
            lg={campaign.emailChannelId ? 4 : 6}
          >
            <div className="sendNowItem">
              <label>Channel </label>
              <select
                name="smsChannelId"
                value={campaign.smsChannelId}
                className="popupDropdown"
                onChange={(event) => {
                  handleChange(event);
                }}
                selected={campaign.smsChannelId}
              >
                <option value={campaign.smsChannelId}>
                  {campaign.smsChannelName}
                </option>
              </select>
            </div>
          </Grid>

          {/* {campaign.isBoth && ( */}
          {campaign.isBoth && (
            <Grid
              item
              xs={12}
              md={campaign.emailChannelId ? 4 : 6}
              lg={campaign.emailChannelId ? 4 : 6}
            >
              <div className="sendNowItem">
                {/* <nobr>
                  {campaign.isBoth &&
                    <>
                      <span className="form-label">
                        Please select email channel
                      </span>
                      <span className="mandatory">*</span>
                    </>
                  }
                  <span className={!campaign.isBoth ? "pt-47 flex-row float-right form-label" : "flex-row float-right form-label"}>
                    {!campaign.isBoth ? 'Send Both Email/SMS' : ''}
                    <Switch
                      checked={campaign.isBoth}
                      onChange={handleChangeSwitch}
                      name="isBoth"
                      className="both-switch-div"
                    />
                  </span>
                </nobr> */}
                <label>Email channel</label>
                <select
                  name="emailChannelId"
                  value={campaign.emailChannelId}
                  id="smsdrop"
                  className="popupDropdown"
                  selected={campaign.emailChannelName}
                  onChange={(event) => {
                    // setChannel(event.target.value);
                    handleChange(event);
                  }}
                >
                  <option value={campaign.emailChannelName}>
                    {" "}
                    {campaign.emailChannelName}{" "}
                  </option>
                </select>
              </div>
            </Grid>
          )}

          {campaign.emailChannelId && (
            <Grid item xs={12} md={4} lg={4}>
              <div className="sendNowItem">
                <label>Email sender</label>
                <select
                  name="fromEmail"
                  value={campaign.fromEmail}
                  selected={campaign.fromEmail}
                  id="smsdrop"
                  className="popupDropdown"
                  // onChange={handleChange}
                >
                  <option value={campaign.fromEmail}>
                    {" "}
                    {campaign.fromEmail}
                  </option>
                </select>
              </div>
            </Grid>
          )}

          {/* SMS body and inputs */}
          {campaign.smsChannelId && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                  <h5>SMS Details</h5>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                lg={12}
                spacing={2}
                className="sub-container hidden-container ptb-0"
              >
                {/* <Grid item xs={12} md={12} lg={12} className="mt-25">
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} className="mt-25">
                  <div className="sendNowItem">
                    {/* <label>
                      {campaign.isBoth ? "Campaign Name / Email Subject" : "Campaign Name"} <span className="mandatory">*</span>
                    </label> */}
                    <span className="form-label">
                      {campaign.isBoth
                        ? "Campaign Name / Email Subject"
                        : "Campaign Name"}
                    </span>
                    <nobr>
                      {/* <span className="mandatory">*</span>
                                            <span className="flex-row float-right form-label">
                                                Send Both Email/SMS
                                                <Switch
                                                    checked={campaign.isBoth}
                                                    onChange={handleChangeSwitch}
                                                    name="isBoth"
                                                />
                                            </span> */}
                    </nobr>
                    <input
                      value={campaign.campaignName}
                      onChange={handleChange}
                      name="campaignName"
                      type="text"
                      placeholder="Give your campaign a unique name"
                      className="nameimage pl-60"
                      readOnly
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {campaign.isBulk === true && !campaign.isBoth ? (
                    <>
                      <div className="sendNowItem pt-25">
                        <nobr>
                          {selectedContactList.length >= 1 && (
                            <span className="form-label border-box">
                              {selectedContactList.length} contacts selected
                              <CheckIcon color="success" className="ml-15" />
                            </span>
                          )}
                          <span className="flex-row float-right form-label">
                            Upload Contacts
                            <Switch
                              checked={campaign.isBulk ? "checked" : ""}
                              onChange={handleChangeSwitch}
                              name="isBulk"
                            />
                          </span>
                        </nobr>
                      </div>
                    </>
                  ) : (
                    <>
                      {!campaign.isBoth && (
                        <>
                          <div className="sendNowItem">
                            <nobr>
                              <span className="form-label">
                                Add Contact No.
                              </span>
                              <span className="mandatory">*</span>
                              <span className="flex-row float-right form-label">
                                Upload Contacts
                                <Switch
                                  checked={campaign.isBulk ? "checked" : ""}
                                  onChange={handleChangeSwitch}
                                  name="isBulk"
                                />
                              </span>
                            </nobr>
                            <input
                              value={campaign.contactNumber}
                              onChange={(e) => {
                                setSelectedContact(null);
                                handleChange(e);
                              }}
                              name="contactNumber"
                              type="text"
                              placeholder="Enter contact number"
                              className="nameimage pl-60"
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="pb-25">
                  <div className="templateButtonContainer">
                    <label className="form-label pt-35 pb-15">
                      Enter SMS Body Text <span className="mandatory">*</span>
                    </label>
                    <label className="d-flex justify-content-between">
                      <div className="select_template_div pt-10">
                        {!isEmailTemplateApplied ? (
                          <button
                            className="templateElementButton template-button"
                            onClick={openPopup}
                          >
                            <img src={templatesIcon} className="filter-red" />
                            <span className="templateButtonText">
                              Select Template
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-white-red mr-20"
                            onClick={() => removeAppliedTemplate("email")}
                          >
                            <span className="templateButtonText">
                              Remove Template
                            </span>
                          </button>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <textarea
                      value={campaign.smsMessageBody}
                      onChange={handleChange}
                      name="smsMessageBody"
                      placeholder="Enter the body of your SMS message here"
                      disabled={
                        isSMSTemplateApplied &&
                        !userSettings.enable_sms_template_edit
                      }
                      readOnly
                    ></textarea>
                  </div>
                </Grid>
                {!campaign.isBoth ||
                (campaign.isBoth && campaign.emailChannelId === "") ? (
                  <Grid item xs={12} md={12} lg={12} className="pb-25">
                    <div className="templateButtonContainerWithPreview flex-row-reverse">
                      <button
                        disabled={sending}
                        className="sendNowButton"
                        onClick={handleSubmit}
                      >
                        Send Now
                      </button>
                      <button
                        disabled={sending}
                        className="previewButton"
                        onClick={previewMessage}
                      >
                        Preview Message
                      </button>
                    </div>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <h3 className="popupTitle">
                  Please select your message template
                </h3>
                <select
                  value={template}
                  className="popupDropdown"
                  onChange={(event) => setTemplate(event.target.value)}
                >
                  <option value="" disabled>
                    Select Template
                  </option>
                  {data
                    .filter(
                      (template) => template.type === SEND_THROUGH_EMAIL_VALUE,
                    )
                    .map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.templatename}
                      </option>
                    ))}
                </select>
                <button
                  className="sendNowButton"
                  disabled={template === ""}
                  onClick={applyTemplate}
                >
                  Apply
                </button>
              </CustomPopup>
              <CustomPopup
                trigger={smsButtonPopup}
                setTrigger={setSmsButtonPopup}
              >
                <h3 className="popupTitle">
                  Please select your message template
                </h3>
                <select
                  value={smsTemplate}
                  className="popupDropdown"
                  onChange={(event) => setSmsTemplate(event.target.value)}
                >
                  <option value="" disabled>
                    Select SMS Template
                  </option>
                  {data
                    .filter(
                      (template) => template.type !== SEND_THROUGH_EMAIL_VALUE,
                    )
                    .map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.templatename}
                      </option>
                    ))}
                </select>
                <button
                  className="sendNowButton"
                  disabled={smsTemplate === ""}
                  onClick={(e) => applyTemplate(e, true)}
                >
                  Apply
                </button>
              </CustomPopup>
            </>
          )}

          {/* Email body and inputs */}
          {/* {campaign.isBoth && smsChannel && channel && ( */}
          {/* //campaign.isBoth && campaign.smsChannelId &&  */}
          {campaign.emailChannelId && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                  <h5> Email Details </h5>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                lg={12}
                spacing={2}
                className="sub-container hidden-container ptb-0"
              >
                <Grid item xs={12} md={12} lg={12} className="mt-25">
                  <div className="sendNowItem">
                    {!campaign.isBoth && (
                      <span className="form-label">
                        {campaign.isBoth
                          ? "Campaign Name  / Email Subject"
                          : "Email Subject"}
                      </span>
                    )}
                    {!campaign.isBoth && campaign.type === 11 && (
                      <span className="flex-row float-right form-label">
                        <nobr>
                          Send Both Email/SMS
                          <Switch
                            checked={campaign.isBoth}
                            onChange={handleChangeSwitch}
                            name="isBoth"
                          />
                        </nobr>
                      </span>
                    )}
                    {!campaign.isBoth && (
                      <input
                        value={campaign.campaignName}
                        onChange={handleChange}
                        name="campaignName"
                        type="text"
                        placeholder="Enter email subject here"
                        className="nameimage pl-60"
                        readOnly
                      />
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  {campaign.isBulk === true && !campaign.isBoth ? (
                    <>
                      <div className="sendNowItem pt-25">
                        <nobr>
                          {selectedContactList.length >= 1 && (
                            <span className="form-label border-box">
                              {selectedContactList.length} contacts selected
                              <CheckIcon color="success" className="ml-15" />
                            </span>
                          )}
                          <span className="flex-row float-right form-label">
                            Upload Contacts
                            <Switch
                              checked={campaign.isBulk ? "checked" : ""}
                              onChange={handleChangeSwitch}
                              name="isBulk"
                            />
                          </span>
                        </nobr>
                      </div>
                    </>
                  ) : (
                    <>
                      {!campaign.isBoth && (
                        <div className="sendNowItem">
                          <nobr>
                            <span className="form-label">Add Email</span>
                            <span className="mandatory">*</span>
                            <span className="flex-row float-right form-label">
                              Upload Contacts
                              <Switch
                                checked={campaign.isBulk ? "checked" : ""}
                                onChange={handleChangeSwitch}
                                name="isBulk"
                              />
                            </span>
                          </nobr>
                          <input
                            value={campaign.email}
                            onChange={(e) => {
                              setSelectedContact(null);
                              handleChange(e);
                            }}
                            name="email"
                            type="text"
                            placeholder="Enter an email"
                            className="nameimage pl-60"
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                {campaign.isBulk && (
                  <Grid item xs={12} md={12} lg={12}>
                    <div className="sendNowItem">
                      <label>
                        Choose Greetings <span className="mandatory">*</span>
                      </label>
                      <select
                        value={campaign.greetingText}
                        onChange={handleChange}
                        name="greetingText"
                        className="popupDropdown"
                      >
                        <option value="" disabled>
                          Select Greeting
                        </option>
                        {greetings.map((greet) => (
                          <option key={greet.value} value={greet.value}>
                            {greet.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} className="pb-25">
                  <div className="templateButtonContainer">
                    <label className="form-label pt-35">
                      Enter Email Body Text <span className="mandatory">*</span>
                    </label>
                    <label className="d-flex justify-content-between">
                      <div className="select_template_div pt-10">
                        {!isEmailTemplateApplied ? (
                          <button
                            className="templateElementButton template-button"
                            onClick={openPopup}
                          >
                            <img src={templatesIcon} className="filter-red" />
                            <span className="templateButtonText">
                              Select Template
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-white-red mr-20"
                            onClick={() => removeAppliedTemplate("email")}
                          >
                            <span className="templateButtonText">
                              Remove Template
                            </span>
                          </button>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="ckeditor-grid pb-35"
                >
                  <div id="super-ckeditor"></div>
                </Grid>
                {/* {(!campaign.isBoth || (campaign.isBoth && campaign.smsChannelId === "")) ?
                                    ( */}
                {!campaign.isBoth && (
                  <Grid item xs={12} md={12} lg={12} className="pb-25">
                    <div className="templateButtonContainerWithPreview flex-row-reverse">
                      <button
                        disabled={sending}
                        className="sendNowButton"
                        onClick={handleSubmit}
                      >
                        Send Now
                      </button>
                      <button
                        disabled={sending}
                        className="previewButton"
                        onClick={previewMessage}
                      >
                        Preview Message
                      </button>
                    </div>
                  </Grid>
                )}

                {/* )  */}
                {/* : <Grid item xs={12} md={12} lg={12} className="pb-25"></Grid> */}
                {/* } */}
              </Grid>
            </>
          )}
          {/* When both campaign enable then only below block of code execute */}
          {campaign.isBoth && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                  <h5> Editable details </h5>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                lg={12}
                spacing={2}
                className="sub-container hidden-container ptb-0"
              >
                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    {/* <Grid container item xs={12} md={12} lg={12} spacing={2}> */}
                    <div className="sendNowItem pt-25">
                      <nobr>
                        {selectedContactList.length >= 1 && (
                          <span className="form-label border-box">
                            {selectedContactList.length} contacts selected
                            <CheckIcon color="success" className="ml-15" />
                          </span>
                        )}
                        <span className="flex-row float-right form-label">
                          Upload Contacts
                          <Switch
                            checked={campaign.isBulk ? "checked" : ""}
                            onChange={handleChangeSwitch}
                            name="isBulk"
                          />
                        </span>
                      </nobr>
                    </div>
                    {/* </Grid> */}
                    {selectedContactList.length === 0 && (
                      <>
                        {/* <div className="mt-25"> */}

                        <nobr>
                          <span className="form-label">Add Contact No.</span>
                          <span className="mandatory">*</span>
                        </nobr>
                        {/* </div> */}
                        <input
                          value={campaign.contactNumber}
                          onChange={(e) => {
                            setSelectedContact(null);
                            handleChange(e);
                          }}
                          name="contactNumber"
                          type="text"
                          placeholder="Enter contact number"
                          className="nameimage pl-60"
                        />
                        <div className="mt-25">
                          <nobr>
                            <span className="form-label">Add Email</span>
                            <span className="mandatory">*</span>
                          </nobr>
                        </div>
                        <input
                          value={campaign.email}
                          onChange={(e) => {
                            setSelectedContact(null);
                            handleChange(e);
                          }}
                          name="email"
                          type="text"
                          placeholder="Enter an email"
                          className="nameimage pl-60"
                        />
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="pb-25">
                  <div className="templateButtonContainerWithPreview flex-row-reverse">
                    <button
                      disabled={sending}
                      className="sendNowButton"
                      onClick={handleSubmit}
                    >
                      Send Now
                    </button>
                    <button
                      disabled={sending}
                      className="previewButton"
                      onClick={previewMessage}
                    >
                      Preview Message
                    </button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <BootstrapDialog
        onClose={handleImportModalclose}
        aria-labelledby="customized-dialog-title-1"
        open={opencontactModal}
        className={
          updateModalSize
            ? "importContactInModal extendModalSize"
            : "importContactInModal "
        }
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              className="dialogTitle-component import-contact-header-grid popup-inner"
            >
              Upload Contacts
              <button
                id="mypopup"
                className="popupElementButton close-btn"
                onClick={handleImportModalclose}
              >
                <span className="templateButtonText">X</span>
              </button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} spacing={2}>
              <Importcontacts
                componentCalledSource={
                  campaign.isBoth ? "sms_emailChannel" : "smsChannel"
                }
                getUploadedContactData={getUploadedContactData}
                setUpdateModalSize={setUpdateModalSize}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="customized-dialog-title-1"
        open={openPreviewModal}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              className="dialogTitle-component import-contact-header-grid popup-inner"
            >
              Preview Details
              <button
                id="mypopup"
                className="popupElementButton close-btn"
                onClick={() => setOpenPreviewModal(false)}
              >
                <span className="templateButtonText">X</span>
              </button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} spacing={2}>
              <PreviewMessage
                campaign={previewCampaignData}
                isOpen={openPreviewModal}
                closeModal={() => setOpenPreviewModal(false)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
