import "./newcontact.css";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../../helpers/request";

import { Breadcrumbs, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import Swal from "sweetalert2";

const PHONE_NUMBER_REGEX = new RegExp(/^\+[\d]+$/);
const EMAIL_REGEX = new RegExp(/^\S+@\S+\.\S+$/);
const filter = createFilterOptions();

export default function NewContact() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const isEditable = location.pathname.split("/").includes("contact-edit");
  const currentListId = history.location.state.listId;
  console.log("NewContact-params", params);
  console.log("currentListId", currentListId);
  const [loading, setLoading] = useState(false);

  const [contactData, setData] = useState({
    first_name: "",
    last_name: "",
    number: "",
    email: "",
    listId: currentListId === "all_list" ? "" : currentListId,
    type: "",
  });
  const [currentListInfo, setCurrentListInfo] = useState({ type: "sms_email" });

  const [selectedList, setSelectedList] = useState("");
  const [allList, setAllList] = useState([]);

  const defaultProps = {
    options: allList,
    getOptionLabel: (option) => option.name,
  };

  useEffect(() => {
    getContactList();
    if (isEditable && params.id) {
      getContactDetail();
    }
  }, []);

  const getContactList = () => {
    axios.get(`/all-contact-list`).then(async (response) => {
      if (response.status === 200 && response.data) {
        let templist = response.data;

        if (currentListId !== "all_list") {
          templist = templist.filter((list) => {
            return list.listId == currentListId;
          });
          setCurrentListInfo(templist[0]);
          setData({ ...contactData, type: templist[0].type });
        } else {
          //setSelectedList(templist[0]);
          setAllList(templist);
        }
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const getContactDetail = () => {
    axios
      .get(`/contact/${params.id}`)
      .then((res) => {
        if (res.data && res.data.id) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  let numberEmailInputField = "";
  if (currentListInfo) {
    switch (currentListInfo.type) {
      case "email":
        numberEmailInputField = (
          <div className="newUserItem">
            <label>
              Email <span className="mandatory">*</span>
            </label>
            <input
              name="email"
              type="text"
              onChange={(e) =>
                setData({ ...contactData, email: e.target.value })
              }
              value={contactData.email}
              placeholder="Enter the mail"
            />
          </div>
        );
        break;

      case "sms_email":
        numberEmailInputField = (
          <>
            <div className="newUserItem">
              <label>
                Contact number<span className="mandatory">*</span>
              </label>
              <input
                name="number"
                type="text"
                onChange={(e) =>
                  setData({ ...contactData, number: e.target.value })
                }
                value={contactData.number}
                placeholder="Enter the number"
              />
            </div>
            <div className="newUserItem">
              <label>
                Email <span className="mandatory">*</span>
              </label>
              <input
                name="email"
                type="text"
                onChange={(e) =>
                  setData({ ...contactData, email: e.target.value })
                }
                value={contactData.email}
                placeholder="Enter the mail"
              />
            </div>
          </>
        );
        break;

      default:
        numberEmailInputField = (
          <div className="newUserItem">
            <label>
              Contact number <span className="mandatory">*</span>
            </label>
            <input
              name="number"
              type="text"
              onChange={(e) =>
                setData({ ...contactData, number: e.target.value })
              }
              value={contactData.number}
              placeholder="Enter the number"
            />
          </div>
        );
        break;
    }
  }

  const isValidData = () => {
    const { first_name, last_name, number, email, listId } = contactData;
    let isValid = true;
    let message = "";

    if (first_name == "") {
      isValid = false;
      message = "Please enter the first name";
    } else if (last_name == "") {
      isValid = false;
      message = "Please enter the last name";
    }

    if (currentListId === "all_list" && listId == "") {
      isValid = false;
      message = "Please select list from options";
    }

    if (currentListInfo.type === "sms_email") {
      if (number === "") {
        isValid = false;
        message = "Please enter the contact number";
      } else if (!PHONE_NUMBER_REGEX.test(number)) {
        isValid = false;
        message = "Invalid phone number";
      } else if (email === "") {
        isValid = false;
        message = "Please enter the email";
      } else if (!EMAIL_REGEX.test(email)) {
        isValid = false;
        message = "Invalid email";
      }
    } else if (currentListInfo.type === "email") {
      if (email === "") {
        isValid = false;
        message = "Please enter the email";
      } else if (!EMAIL_REGEX.test(email)) {
        isValid = false;
        message = "Invalid email";
      }
    } else {
      if (number === "") {
        isValid = false;
        message = "Please enter the contact number";
      } else if (!PHONE_NUMBER_REGEX.test(number)) {
        isValid = false;
        message = "Invalid phone number";
      }
    }
    return { isValid, message };
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log("selectedList", selectedList);
    if (
      currentListId === "all_list" &&
      selectedList != "" &&
      selectedList != null
    ) {
      contactData.listId = selectedList.listId;
    }
    const { isValid, message } = isValidData();
    if (isValid) {
      if (!isEditable) {
        axios
          .post("add-contact", {
            ...contactData,
          })
          .then((res) => {
            if (res.data && res.data.affectedRows === 1) {
              history.goBack();
            } else {
              Swal.fire({
                title: "Something went wrong adding contact!",
                confirmButtonColor: "#3085d6",
              });
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            Swal.fire({
              title:
                err?.response?.data?.message ||
                "Something went wrong adding contact!",
              confirmButtonColor: "#3085d6",
            });
            setLoading(false);
            console.log(err);
          });
      } else {
        axios
          .put(`contact/${params.id}`, {
            ...contactData,
          })
          .then((res) => {
            if (
              res.data &&
              res.data.message === "contact updated successfully"
            ) {
              history.goBack();
            } else {
              Swal.fire({
                title: "Something went wrong updating contact!",
                confirmButtonColor: "#3085d6",
              });
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            Swal.fire({
              title:
                err?.response?.data?.message ||
                "Something went wrong updating contact!",
              confirmButtonColor: "#3085d6",
            });
            setLoading(false);
            console.log(err);
          });
      }
    } else {
      setLoading(false);
      Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
    }
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" className="mb-5">
        <Link
          to={
            history.location.state?.listId
              ? `/edit-contact-list/${history.location.state.listId}`
              : "/contacts"
          }
        >
          Contacts
        </Link>
        <Typography color="text.primary">
          {isEditable && params.id ? "Edit Contact" : "New Contact"}
        </Typography>
      </Breadcrumbs>

      <div className="newUserItem">
        <label>List Name</label>
        {currentListId === "all_list" ? (
          <Autocomplete
            value={selectedList != "" ? selectedList : ""}
            onChange={(event, newValue) => {
              console.log("newValue", newValue);
              setSelectedList(newValue);
              if (newValue !== "" && newValue !== null) {
                setCurrentListInfo({ ...currentListInfo, type: newValue.type });
                setData((prevData) => ({ ...prevData, type: newValue.type }));
              }
            }}
            /*filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                /*if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                }*
                return filtered;
            }}*/
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={allList}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            sx={{ width: "100%" }}
            freeSolo
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <input
            name="list name"
            type="text"
            defaultValue={currentListInfo?.name}
            disabled
          />
        )}
      </div>
      <div className="newUserItem">
        <label>
          {"First name"} <span className="mandatory">*</span>
        </label>
        <input
          name="label"
          type="text"
          onChange={(e) =>
            setData({ ...contactData, first_name: e.target.value })
          }
          value={contactData.first_name}
          placeholder="Enter the name"
        />
      </div>
      <div className="newUserItem">
        <label>
          {"Last name"} <span className="mandatory">*</span>
        </label>
        <input
          name="label"
          type="text"
          onChange={(e) =>
            setData({ ...contactData, last_name: e.target.value })
          }
          value={contactData.last_name}
          placeholder="Enter the name"
        />
      </div>
      {numberEmailInputField}
      <div className="newUserItem">
        <button
          className="newChannelButton align-self-end"
          disabled={
            Object.keys(contactData).filter((v) => !v).length > 0 || loading
          }
          onClick={handleSubmit}
        >
          {!isEditable ? "Create" : "Save"}
        </button>
      </div>
    </div>
  );
}
