import "./contacts.css";
import { DataGrid } from "@material-ui/data-grid";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import axios from "../../../helpers/request";
import { Link } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";

export default function Contacts() {
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getContactList = () => {
    axios
      .get(`/all-contact-list`)
      .then(async (response) => {
        if (response.status === 200 && response.data) {
          let templist = [];
          let parentTypes = [];
          response.data.map((row) => {
            if (row.parent_type != "" && row.parent_type != null) {
              if (!parentTypes.includes(row.parent_type)) {
                templist[row.parent_type] = [];
                parentTypes.push(row.parent_type);
              }
              templist[row.parent_type][templist[row.parent_type].length] = row;
            }
          });
          setList(response.data);
          setFilteredList(templist);
          setParentList(parentTypes);
        }
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  useEffect(() => {
    getContactList();
  }, []);

  const handleDeleteList = async (listId, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${label}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      axios
        .delete("/contact-list-delete/" + listId)
        .then(async function (response) {
          getContactList();
          await Swal.fire({
            title: "List deleted successfully",
            confirmButtonColor: "#3085d6",
          });
        })
        .catch(async function (error) {
          await Swal.fire({
            title: error?.response?.data?.message || "Unable to delete contact",
            confirmButtonColor: "#3085d6",
          });
        });
    }
  };

  const deleteContactlistsByParent = async (parentTypeName) => {
    const result = await Swal.fire({
      title: `Are you sure to delete all contact lists with parent type ${parentTypeName}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      axios
        .delete("/delete-contactlists-by-parent/" + parentTypeName)
        .then(function (response) {
          getContactList();
          Swal.fire({
            title: "All list deleted successfully",
            confirmButtonColor: "#3085d6",
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: error?.response?.data?.message || "Unable to delete contact",
            confirmButtonColor: "#3085d6",
          });
        });
    }
  };

  const contactListColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            className="color-gray"
            to={"/view-contact-list/" + params.row.id}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "number_of_contacts", headerName: "Number of contacts", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="userListActions">
            <Link to={"/edit-contact-list/" + params.row.id}>
              <button className="actionElementButton">
                <Tooltip title="Edit contact list">
                  <EditOutlined className="actionElementIcon" />
                </Tooltip>
              </button>
            </Link>
            <Tooltip title="Delete contact list">
              <DeleteOutlined
                className="userListDelete"
                onClick={() => handleDeleteList(params.row.id, params.row.name)}
              />
            </Tooltip>
            <Link to={"/view-contact-list/" + params.row.id}>
              <button className="actionElementButton">
                <Tooltip title="View contact list">
                  <PersonIcon className="actionElementIcon" />
                </Tooltip>
              </button>
            </Link>

            {/* Clone of contact list start*/}
            <Link to={"/contact-list-clone/" + params.row.id}>
              <button className="actionElementButton">
                <Tooltip title="Update Parent type of contact lists">
                  <ContentCopyIcon className="actionElementIcon" />
                </Tooltip>
              </button>
            </Link>
            {/* Clone of contact list end*/}
          </div>
        );
      },
    },
  ];

  return (
    <div className="contactlist-page">
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to="/create-contact-list">
            <button className="btn btn-primary text-white br-10">
              Add List
            </button>
          </Link>
        </div>
      </div>

      {parentList.map((parentTypeName) => {
        return (
          <>
            <Accordion
              className="parentAccordion"
              expanded={expanded === parentTypeName}
              onChange={handleChange(parentTypeName)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid
                  container
                  spacing={2}
                  className="ptb-0 main-grid-container"
                >
                  <Grid container item xs={6} md={6} lg={6}>
                    <span className="ml-15">{parentTypeName}</span>
                  </Grid>
                  <Grid container item xs={5} md={5} lg={5}>
                    {filteredList[parentTypeName].length} Contact Lists
                  </Grid>
                  <Grid container item xs={1} md={1} lg={1}>
                    <div className="contactlist-action-buttons">
                      <Link
                        to={"/create-contactlist-by-parent/" + parentTypeName}
                      >
                        <button className="actionElementButton">
                          <Tooltip title="Add new contact list">
                            <AddCircleOutlineIcon className="actionElementIcon" />
                          </Tooltip>
                        </button>
                      </Link>
                      <Link
                        to={
                          "/edit-contactlist-parent/" +
                          filteredList[parentTypeName][0].listId
                        }
                      >
                        <button className="actionElementButton">
                          <Tooltip title="Update Parent type of contact lists">
                            <EditOutlined className="actionElementIcon" />
                          </Tooltip>
                        </button>
                      </Link>
                      <button
                        className="actionElementButton"
                        onClick={() =>
                          deleteContactlistsByParent(parentTypeName)
                        }
                      >
                        <Tooltip title="Delete Parent type contact lists">
                          <DeleteOutlined className="actionElementIcon" />
                        </Tooltip>
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <DataGrid
                  rows={filteredList[parentTypeName]}
                  columns={contactListColumns}
                  pageSize={10}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  autoHeight={true}
                />
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </div>
  );
}
