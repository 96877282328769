import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  location,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@moeindana/google-oauth";
import App from "./App";
import Login from "./components/login/Login";
import PasswordReset from "./components/verify/PasswordReset";
import PasswordCreate from "./components/verify/PasswordCreate";
import AppStateProvider, { useAppState } from "./state";
import SignUp from "./components/signup/SignUp.jsx";
import Home from "./components/home/Home.jsx";
import Contact from "./components/contact/Contact";
import { Stripe } from "./components/Stripe";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { socket, SocketContext } from "./socket";
import LoaderContainer from "./components/common/LoaderContainer";

function Main() {
  const { isLoggedIn, user } = useAppState();

  if (isLoggedIn) {
    // if (user && !user.subscription_id && !user.trial) return <Stripe />;
    // else
    return <App />;
  }

  console.log(user);
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/passwordreset">
          <PasswordReset />
        </Route>
        <Route path="/passwordcreate">
          <PasswordCreate />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        {/* <Route path="/contact">
          <Contact />
        </Route> */}
        {/* <Route path="/plans">
          <Stripe />
        </Route> */}
      </Switch>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="709900591742-vi8jgeg2vggclq119lc8kvhpd2aseldp.apps.googleusercontent.com">
    <SocketContext.Provider value={socket}>
      <Router>
        <AppStateProvider>
          <LoaderContainer />
          <Main />
        </AppStateProvider>
      </Router>
    </SocketContext.Provider>
  </GoogleOAuthProvider>,
);
