import axios from "../../../helpers/request";
import "./templates.css";
import { DataGrid } from "@material-ui/data-grid";
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
  AddOutlined,
  LayersOutlined,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppState } from "../../../state";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";

export default function Templates() {
  const [data, setData] = useState([]);
  const { user } = useAppState();

  const handleDelete = async (id, name) => {
    // setData(data.filter((item) => item.id !== id));
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + "/template/" + id)
        .then(function (response) {
          getTemplates();
          Swal.fire({
            title: "Template deleted successfully",
            confirmButtonColor: "#3085d6",
          });
        })
        .catch(function (error) {
          Swal.fire({
            title:
              error?.response?.data?.message || "Unable to delete template",
            confirmButtonColor: "#3085d6",
          });
        });
    }
  };

  const getTemplates = () => {
    console.log("USER::", user);
    let queryParams = {
      companyId: user.added_by ? user.added_by : user.id,
    };
    axios.get("/templates", { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        setData(response.data);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false },
    {
      field: "templatename",
      headerName: "Template Name",
      width: 200,
      sortable: false,
    },
    {
      field: "lastmodified",
      headerName: "Last Modified",
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "lastmodifiedby",
      headerName: "Last Modified By",
      width: 200,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Template Type",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.type && params.row.type === "email" ? "EMAIL" : "SMS"}
          </div>
        );
      },
    },
    // { field: 'templatebody', headerName: 'Template Body', width: 200, sortable: false },
    {
      field: "action",
      headerName: "Action Taken",
      width: 400,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="userListActions">
            <Link to={"/template-view/" + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={"/template-edit/" + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>
            {/* Clone of SMS/Email Template start */}
            <Link to={"/template-clone/" + params.row.id}>
              <button className="actionElementButton">
                <ContentCopyIcon className="actionElementIcon" />
                <span className="actionButtonText">Clone</span>
              </button>
            </Link>
            {/* Clone of SMS/Email Template end */}
            <DeleteOutlined
              className="userListDelete"
              onClick={() =>
                handleDelete(params.row.id, params.row.templatename)
              }
            />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to="/newtemplate">
            <button className="btn btn-primary text-white br-10">Add</button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        autoHeight={true}
      />
    </div>
  );
}
