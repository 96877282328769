import { LayersOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import "./campaigndetail.css";
import { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "../../../helpers/request";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Button } from "reactstrap";
import SentsTable from "./SentsTable";
import EmailsTable from "./EmailsTable";
import ConversationsTable from "./ConversationsTable";
import Swal from "sweetalert2";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// below are set in sent/sechedule message APIs
const EMAIL_SLUG = "##EMAIL_BODY## :";
const SMS_SLUG = "##MESSAGE_TEXT## :";

export default function CampaignDetail() {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});
  const [chartLabels, setChartLabels] = useState([]);
  const [chartDataSets, setChartDataSets] = useState([]);
  const [chartData, setChartData] = useState({
    datasets: [],
    options: { responsive: true },
    labels: [],
  });
  const [innerChartData, setInnerChartData] = useState({
    datasets: [],
    options: { responsive: true },
    labels: [],
  });
  const [innerDonutPosition, setInnerDonutPosition] = useState({});
  const [emailContent, setEmailContent] = useState("");
  const [smsContent, setSMSContent] = useState("");
  const getCampaigns = () => {
    axios.get("/analytic-detail/" + campaignId).then(async (response) => {
      if (response.status === 200 && response.data) {
        setCampaign(response.data[0]);
        setCampaignCounts(response.data[0]);
        setCampaignContent(response.data[0]);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const setCampaignCounts = (campaignData) => {
    const { type } = campaignData;
    const {
      failedSMS,
      droppedEmail,
      bouncedEmail,
      failedWP,
      deliveredEmail,
      deliveredSMS,
      deliveredWP,
      clickedEmail,
      processedEmail,
      openedEmail,
      processedSMS,
      readWP,
      processedWP,
      deferredEmail,
    } = campaignData;

    if (type == 11) {
      setChartLabels(["SMS Sent", "SMS Failed", "Email Sent", "Email Failed"]);
      setChartDataSets([
        {
          label: "",
          data: [Number(deliveredSMS), Number(failedSMS), 0, 0],
          backgroundColor: [
            "rgba(97, 184, 91, 1)",
            "rgba(220, 67, 76, 1)",
            "#46BFBD",
            "rgba(220, 67, 76, 1)",
          ],
          borderWidth: 2,
          setChartData,
        },
        {
          label: "",
          data: [
            0,
            0,
            Number(deliveredEmail),
            Number(droppedEmail) + Number(bouncedEmail),
          ],
          backgroundColor: [
            "rgba(97, 184, 91, 1)",
            "rgba(220, 67, 76, 1)",
            "#46BFBD",
            "rgba(220, 67, 76, 1)",
          ],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 2,
        },
      ]);
    } else if (type == 2) {
      // setCampaignTotalCount(Number(totalwpmessages))
      // setCampaignErrorCount(Number(failedWP));
      setChartLabels([
        "WhatsApp Sent",
        "WhatsApp Failed",
        "WhatsApp Read",
        "WhatsApp Processed",
      ]);
      setChartDataSets([
        {
          // label: "# of Messages",
          data: [
            Number(deliveredWP),
            Number(failedWP),
            Number(readWP),
            Number(processedWP),
          ],
          backgroundColor: [
            "rgba(97, 184, 91, 1)",
            "rgba(220, 67, 76, 1)",
            "rgb(9, 65, 5)",
            "rgb(6, 27, 146)",
          ],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 2,
        },
      ]);
    } else if (type == 1) {
      // setCampaignTotalCount(Number(totalemails))
      // setCampaignErrorCount(Number(droppedEmail) + Number(bouncedEmail));

      setChartDataSets([
        {
          data: [
            // Number(deliveredEmail), // Email Sent
            Number(droppedEmail) + Number(bouncedEmail), // Email Failed
            Number(deliveredEmail), // Total Email Delivered
            Number(clickedEmail), // Total Email Clicked
            Number(processedEmail), // Total Email Processed
            Number(openedEmail), // Total Email Opened
            Number(deferredEmail), // Total Email Opened
          ],
          backgroundColor: [
            "rgb(236, 236, 115)", // Email Failed
            "#FF6384", // Total Email Delivered (Change color if necessary)
            "rgb(111, 240, 37)", // Total Email Clicked (Change color if necessary)
            "#36A2EB", // Total Email Processed (Change color if necessary)
            "#4BC0C0", // Total Email Opened (Change color if necessary)
            "rgb(9, 65, 5)", // Email Sent
          ],
          borderWidth: 0,
        },
      ]);
      setChartLabels([
        "Failed",
        "Delivered",
        "Clicked",
        "Processed",
        "Opened",
        "Deferred",
      ]);
    } else if (type == 0) {
      // setCampaignTotalCount(Number(totalsents))
      // setCampaignErrorCount(Number(failedSMS));
      setChartLabels(["SMS Sent", "SMS Failed", "SMS Processed"]);
      setChartDataSets([
        {
          // label: "# of Messages",
          data: [Number(deliveredSMS), Number(failedSMS), Number(processedSMS)],
          backgroundColor: [
            "rgba(97, 184, 91, 1)",
            "rgba(220, 67, 76, 1)",
            "rgb(9, 65, 5)",
          ],
          // borderColor: ["rgba(97, 184, 91, 1)", "rgba(220, 67, 76, 1)"],
          borderWidth: 2,
        },
      ]);
    }
  };

  const setCampaignContent = (campaignData) => {
    const { type, smsbody } = campaignData;
    let emailString = "",
      smsString = "";
    // console.log("smsbody", smsbody)

    if (type == 0 || type == 1 || type == 11) {
      let messageParts = smsbody.split(EMAIL_SLUG);
      // console.log("messageParts", messageParts)

      if (messageParts.length > 1) {
        emailString = messageParts[1];
        // console.log("emailString", emailString)
        setEmailContent(emailString);
      }

      let messageSMSParts = messageParts[0].split(SMS_SLUG);
      // console.log("messageSMSParts", messageSMSParts)

      if (messageSMSParts.length > 1) {
        smsString = messageSMSParts[1];
        // console.log("smsString", smsString)
        setSMSContent(smsString);
      }
    }
  };
  useEffect(() => {
    getCampaigns();
    ChartJS.register(ArcElement, Tooltip, Legend);
  }, []);

  useEffect(() => {
    setChartData({
      datasets: chartDataSets,
      options: {
        responsive: true,
      },
      labels: chartLabels,
    });
  }, [chartLabels, chartDataSets]);

  useEffect(() => {
    setInnerChartData({
      datasets: chartDataSets,
      options: {
        responsive: true,
      },
      labels: [],
    });
  }, [chartDataSets]);
  // useEffect(() => {
  //   // Get the dimensions of the outer donut container
  //   const outerDonutContainer = document.querySelector(".campaigndetailschart canvas");
  //   const outerDonutRect = outerDonutContainer.getBoundingClientRect();

  //   // Calculate the position of the inner donut relative to the outer donut
  //   const innerDonutRadius = outerDonutRect.width * 0.3; // Example inner donut size relative to outer donut

  //   // Calculate the position
  //   const position = {
  //     top: outerDonutRect.height / 1.9 - innerDonutRadius,
  //     left: outerDonutRect.width / 1.8 - innerDonutRadius,
  //   };

  //   setInnerDonutPosition(position);
  // }, [chartData]);

  const handleDownload = async (dataType, event) => {
    event.preventDefault();

    try {
      // Fetch all data without pagination
      const response = await axios.get(
        `/emails/${campaign.campaignid}?filter=${dataType}`,
        {
          params: {
            page_size: -1,
            page_number: -1,
          },
        },
      );
      const responseData = response.data;

      // Extract data array from response
      const filteredData = responseData;

      if (filteredData.length > 0) {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          filteredData
            .map((entry) => Object.values(entry).join(","))
            .join("\n");
        const encodedUri = encodeURI(csvContent);

        // Create a temporary link
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${dataType}_data.csv`);

        // Trigger the download
        link.click();
      } else {
        await Swal.fire({
          title: "No data available for download.",
          confirmButtonColor: "#3085d6",
        });
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      await Swal.fire({
        title: "Failed to fetch filtered data. Please try again later.",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const handleSMSDownload = async (dataType, event) => {
    event.preventDefault();

    try {
      // Fetch all data without pagination
      const response = await axios.get(
        `/sents/${campaign.campaignid}?filter=${dataType}`,
        {
          params: {
            page_size: -1,
            page_number: -1,
          },
        },
      );
      const responseData = response.data;

      // Extract data array from response
      const filteredData = responseData;

      if (filteredData.length > 0) {
        const csvContent =
          "data:text/csv;charset=utf-8," +
          filteredData
            .map((entry) => Object.values(entry).join(","))
            .join("\n");
        const encodedUri = encodeURI(csvContent);

        // Create a temporary link
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${dataType}_data.csv`);

        // Trigger the download
        link.click();
      } else {
        await Swal.fire({
          title: "No data available for download.",
          confirmButtonColor: "#3085d6",
        });
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      await Swal.fire({
        title: "Failed to fetch filtered data. Please try again later.",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytics">Analytics</Link>
        <Typography color="text.primary">Campaign Details</Typography>
      </Breadcrumbs>

      <div className="campaigndetailscontainer">
        <div className="w-100">
          <div className="d-flex">
            <div className="campaignItem">
              <label>Campaign Name</label>
              <span className="dataDescriptionSM">{campaign.campaignname}</span>
            </div>
            <div className="campaignItem">
              <label>Sent By</label>
              <span className="dataDescriptionSM">{campaign.sentby}</span>
            </div>
            <div className="campaignItem">
              <label>Date Sent</label>
              <span className="dataDescriptionSM">
                {campaign.datetimesent
                  ? moment(campaign.datetimesent).format("DD/MM/YYYY kk:mm")
                  : ""}
              </span>
            </div>
          </div>

          <form className="d-flex justify-content-between">
            <div className="campaignForm">
              <div className="main-pie-chart">
                <div className="d-flex justify-content-center my-1 gap-3">
                  <label
                    style={{
                      color: "#003a41",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Total Messages
                  </label>
                  <span
                    style={{
                      color: "#757575",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    {campaign.type == 2
                      ? campaign.totalwpmessages
                      : campaign.totalmessages}
                  </span>
                </div>
                <Doughnut
                  data={chartData}
                  options={{
                    plugins: { legend: { position: "bottom" } },
                    cutout: "75%",
                  }}
                />
              </div>
              {(campaign.type == 0 || campaign.type == 11) && (
                <>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Processed {campaign.type == 11 ? "SMS" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.processedSMS)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleSMSDownload("processedSMS", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.processedSMS) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Delivered {campaign.type == 11 ? "SMS" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.deliveredSMS)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleSMSDownload("deliveredSMS", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.deliveredSMS) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>Failed {campaign.type == 11 ? "SMS" : ""}</label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.failedSMS)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleSMSDownload("failedSMS", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.failedSMS) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                </>
              )}
              {(campaign.type == 1 || campaign.type == 11) && (
                <>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Processed {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.processedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("processedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.processedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Delivered {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.deliveredEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("deliveredEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.deliveredEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Clicked {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.clickedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("clickedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.clickedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Opened {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.openedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("openedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.openedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        // text={Number(campaign.openedEmail)}
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Unique Clicked {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.uniqueClickedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("uniqueClickedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.uniqueClickedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Unique Opened {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.uniqueOpenedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("uniqueOpenedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.uniqueOpenedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        // text={Number(campaign.uniqueOpenedEmail)}
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>

                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Deferred {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.deferredEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("deferredEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.deferredEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Bounced {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.bouncedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("bouncedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.bouncedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Dropped {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.droppedEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("droppedEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.droppedEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        Invalid {campaign.type == 11 ? "Emails" : ""}
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.invalidEmail)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("invalidEmail", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.invalidEmail) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                </>
              )}
              {campaign.type == 2 && (
                <>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        {campaign.type == 2 ? "WP" : ""} Messages Processed
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.processedWP)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("processedWP", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.processedWP) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        {campaign.type == 2 ? "WP" : ""} Messages Delivered
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.deliveredWP)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("deliveredWP", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.deliveredWP) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>

                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        {campaign.type == 2 ? "WP" : ""} Messages Read
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.readWP)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("readWP", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.readWP) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>

                  <div className="circularBarBlock">
                    <div className="d-flex flex-column">
                      <label>
                        {campaign.type == 2 ? "WP" : ""} Messages Failed
                      </label>
                      <span className="dataDescriptionLG">
                        {Number(campaign.failedWP)}
                      </span>
                      <button
                        style={{ width: "fit-content", border: "0" }}
                        className="mt-2 px-2 rounded"
                        onClick={(e) => handleDownload("failedWP", e)}
                      >
                        <FileDownloadOutlinedIcon />
                        Export
                      </button>
                    </div>
                    <div className="py-3" style={{ width: "100px" }}>
                      <CircularProgressbar
                        value={
                          (Number(campaign.failedWP) /
                            Number(campaign.totalmessages)) *
                          100
                        }
                        styles={buildStyles({
                          textColor: "white",
                          pathColor: "rgba(97, 184, 91, 1)", // Change color as needed
                          trailColor: "rgba(220, 67, 76, 1)", // Change color as needed
                        })}
                        strokeWidth={15}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="userTables">
        <div className="userTablesTop">
          {(campaign.type == 0 || campaign.type == 11) && (
            <>
              <SentsTable campaignid={campaign.campaignid} />
              <div className="bodycontainer">
                <div className="bodyItem">
                  <h3 className="pageTableTitle">SMS Body</h3>
                  <span className="dataDescriptionSM">{smsContent}</span>
                </div>
              </div>
            </>
          )}
          {(campaign.type == 1 || campaign.type == 11) && (
            <>
              <EmailsTable campaignid={campaign.campaignid} />
              <div className="bodycontainer">
                <div className="bodyItem">
                  <h3 className="pageTableTitle">Email Body</h3>
                  <span
                    className="dataDescriptionSM"
                    dangerouslySetInnerHTML={{ __html: emailContent }}
                  ></span>
                </div>
              </div>
            </>
          )}
          {campaign.type == 2 && (
            <ConversationsTable campaignid={campaign.campaignid} />
          )}
        </div>
      </div>
    </div>
  );
}
