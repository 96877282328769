import { useState, useRef } from "react";
import csv from "jquery-csv";
import axios from "../../../helpers/request";

import "./sms.css";
import { SendOutlined, LayersOutlined } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "react-select";

import CustomPopup from "../../Popup/CustomPopup";

import { useAppState } from "../../../state";

/*import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';*/
import CustomImageUploadAdapter from "../../common/ckeditorImageUploadAdaptor";
import templatesIcon from "../../images/templates.svg";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const SEND_THROUGH_SMS_VALUE = "sms";
const SEND_THROUGH_EMAIL_VALUE = "email";

const PHONE_FIELD = "Contact Number";
const PHONE_NUMBER_REGEX = new RegExp(/^\+[\d]+$/);

const EMAIL_FIELD = "Email";
const FIRST_NAME_FIELD = "First Name";
const LAST_NAME_FIELD = "Last Name";
const EMAIL_REGEX = new RegExp(/^\S+@\S+\.\S+$/);

const greetings = [
  { label: "Hi @FirstName", value: "greetHiFirst" },
  { label: "Hi @FirstName @LastName", value: "greetHiFirstLast" },
  { label: "Hello @FirstName", value: "greetHelloFirst" },
  { label: "Hello @FirstName @LastName", value: "greetHelloFirstLast" },
];

const GREET_DEFAULT_VALUE = greetings[0].value;

export default function SendNow() {
  const [campaign, setCampaign] = useState({
    campaignName: "",
    contactNumber: "",
    messageBody: "",
    file: [],
    isBulk: false,
    sendThrough: SEND_THROUGH_SMS_VALUE,
    greetingText: GREET_DEFAULT_VALUE,
    email: "",
  });
  const fileInputRef = useRef();

  const [channel, setChannel] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [sending, setSending] = useState(false);
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState("");
  const { user: loggedUser, setIsLoading } = useAppState();

  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");
  const getChannelList = (companyId) => {
    axios.get(`/all-channels/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log(response.data);
        setChannelList(response.data);
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const getContactList = (companyId) => {
    axios.get(`/all-contacts/${companyId}`).then(async (response) => {
      if (response.status === 200 && response.data) {
        console.log(response.data);
        setContactList(response.data.filter((v) => v.type == "phone"));
      } else {
        throw new Error(response?.error);
      }
    });
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by
            ? res.data[0].added_by
            : res.data[0].id;
          getChannelList(cmpny);
          getContactList(cmpny);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, []);

  const openPopup = (event) => {
    event.preventDefault();
    setData([]);
    setTemplate("");
    getTemplates();
    setButtonPopup(true);
  };

  const getTemplates = () => {
    const { sendThrough } = campaign;
    let queryParams = {
      companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id,
    };
    axios.get("/templates", { params: queryParams }).then(async (response) => {
      if (response.status === 200 && response.data) {
        const templates = response.data;
        const emailTemplates = templates.filter(
          (template) => template.type === SEND_THROUGH_EMAIL_VALUE,
        );
        const smsTemplates = templates.filter(
          (template) => template.type !== SEND_THROUGH_EMAIL_VALUE,
        );

        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          setData(smsTemplates);
        } else {
          setData(emailTemplates);
        }
      } else {
        throw new Error(response?.error);
      }
    });
  };

  const resetSendThroughDetails = () => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      contactNumber: "",
      messageBody: "",
      email: "",
      file: [],
    }));
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "sendThrough") {
      resetSendThroughDetails();
    }

    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: value,
    }));
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    setSelectedContact(null);
    setSelectedContactList([]);
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: checked,
    }));
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const { sendThrough } = campaign;

    const reader = new FileReader();
    const file = event.target.files[0];
    if (
      file &&
      ["csv"].indexOf(file.name.split(".").pop().toLowerCase()) > -1
    ) {
      try {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          const keys_to_keep = [PHONE_FIELD];

          reader.onloadend = function (event) {
            const csvData = event.target.result.replace(/"/g, "");
            const fileData = csv.toObjects(csvData);

            fileData.map((row) => {
              Object.keys(row).forEach((key) => {
                if (!keys_to_keep.includes(key)) {
                  delete row[key];
                }
              });
              return row;
            });

            fileData.map((field) => {
              if (field[PHONE_FIELD] && !field[PHONE_FIELD].startsWith("+")) {
                field[PHONE_FIELD] = "+" + field[PHONE_FIELD];
              }
              return field;
            });

            const filteredFileData = fileData.filter(
              (value, index, self) =>
                value[PHONE_FIELD] &&
                index ===
                  self.findIndex((t) => t[PHONE_FIELD] === value[PHONE_FIELD]),
            );

            setCampaign((prevCampaign) => ({
              ...prevCampaign,
              file: filteredFileData,
            }));
          };
          reader.readAsText(file);
        } else {
          const keys_to_keep = [EMAIL_FIELD, FIRST_NAME_FIELD, LAST_NAME_FIELD];

          reader.onloadend = function (event) {
            const csvData = event.target.result.replace(/"/g, "");
            const fileData = csv.toObjects(csvData);

            fileData.map((row) => {
              Object.keys(row).forEach((key) => {
                if (!keys_to_keep.includes(key)) {
                  delete row[key];
                }
              });
              return row;
            });

            const filteredFileData = fileData.filter(
              (value, index, self) =>
                value[EMAIL_FIELD] &&
                index ===
                  self.findIndex((t) => t[EMAIL_FIELD] === value[EMAIL_FIELD]),
            );

            setCampaign((prevCampaign) => ({
              ...prevCampaign,
              file: filteredFileData,
            }));
          };
          reader.readAsText(file);
        }
      } catch (e) {
        console.log("ERROR-FILE--", e);
      }
    } else {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        file: [],
      }));
      fileInputRef.current.value = "";
      await Swal.fire({
        title: "Only CSV file allowed",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const applyTemplate = () => {
    const selectedTemplate = data.filter((temp) => temp.id == template);

    if (selectedTemplate && selectedTemplate[0]) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        messageBody: selectedTemplate[0].templatebody,
      }));
    }

    setButtonPopup(false);
  };

  const resetForm = () => {
    setCampaign({
      campaignName: "",
      contactNumber: "",
      messageBody: "",
      file: [],
      isBulk: false,
      sendThrough: SEND_THROUGH_SMS_VALUE,
      greetingText: GREET_DEFAULT_VALUE,
      email: "",
    });
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isValidData = () => {
    const {
      campaignName,
      contactNumber,
      isBulk,
      file,
      messageBody,
      sendThrough,
      email,
    } = campaign;
    let isValid = true;
    let message = "";

    if (campaignName === "" || messageBody === "") {
      isValid = false;
      message = "All fields are mandatory";
    } else {
      if (isBulk === true) {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          if (file.length === 0 && selectedContactList.length == 0) {
            isValid = false;
            message = "Please select file";
          } else {
            let invalidData = file.filter((field) => {
              const inValidPhoneNumber = field[PHONE_FIELD]
                ? !PHONE_NUMBER_REGEX.test(field[PHONE_FIELD])
                : true;
              return inValidPhoneNumber === true;
            });

            if (invalidData.length) {
              isValid = false;
              message = "File contains invalid values";
            }
          }
        } else {
          if (file.length === 0) {
            isValid = false;
            message = "Please select file";
          } else {
            let invalidData = file.filter((field) => {
              const inValidEmail = field[EMAIL_FIELD]
                ? !EMAIL_REGEX.test(field[EMAIL_FIELD])
                : true;
              return (
                inValidEmail === true ||
                field[FIRST_NAME_FIELD] == undefined ||
                field[FIRST_NAME_FIELD] == "" ||
                field[LAST_NAME_FIELD] == undefined ||
                field[LAST_NAME_FIELD] == ""
              );
            });

            if (invalidData.length) {
              isValid = false;
              message = "File contains invalid values";
            }
          }
        }
      } else {
        if (sendThrough === SEND_THROUGH_SMS_VALUE) {
          if (contactNumber === "") {
            isValid = false;
            message = "Please enter the contact number";
          } else if (!PHONE_NUMBER_REGEX.test(contactNumber)) {
            isValid = false;
            message = "Invalid phone number";
          }
        } else {
          if (email === "") {
            isValid = false;
            message = "Please enter the email";
          } else if (!EMAIL_REGEX.test(email)) {
            isValid = false;
            message = "Invalid email";
          }
        }
      }
    }

    return { isValid, message };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();
    const { sendThrough } = campaign;

    if (isValid) {
      setSending(true);
      let currentChannelData = channelList.filter((v) => v.id == channel);
      currentChannelData = currentChannelData.length
        ? currentChannelData[0]
        : null;
      if (sendThrough === SEND_THROUGH_SMS_VALUE && currentChannelData) {
        let { fromdata, sid, token } = currentChannelData;
        setIsLoading(true);
        axios
          .post("/send-message-channel", {
            data: {
              ...campaign,
              file: campaign.file.concat(
                selectedContactList.map((v) => ({ [PHONE_FIELD]: v.value })),
              ),
              fromdata,
              sid,
              token,
              companyid: loggedUser.added_by
                ? loggedUser.added_by
                : loggedUser.id,
            },
            actionBy: loggedUser.fullName,
          })
          .then(async function (response) {
            setIsLoading(false);
            setSending(false);
            resetForm();
            await Swal.fire({
              title: "Sent messages successfully",
              confirmButtonColor: "#3085d6",
            });
          })
          .catch(async function (error) {
            setIsLoading(false);
            setSending(false);
            await Swal.fire({
              title: "Unable to process this request",
              confirmButtonColor: "#3085d6",
            });
          });
      }
    } else {
      await Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
    }
  };

  return (
    <div>
      <div className="newUserItem">
        <label>Please select a channel</label>
        <select
          value={channel}
          className="popupDropdown"
          onChange={(event) => {
            setChannel(event.target.value);
            resetForm();
          }}
        >
          <option value="" disabled>
            Select channel
          </option>
          {channelList
            .filter((v) =>
              ["twilio-messaging-service", "twilio-sms"].includes(v.type),
            )
            .map((chan) => (
              <option key={chan.id} value={chan.id}>
                {chan.label}
              </option>
            ))}
        </select>
      </div>
      {channel && (
        <>
          <form className="sendNowForm">
            <div className="sendNowItem">
              <label>
                SMS Campaign Name <span className="mandatory">*</span>
              </label>
              <input
                value={campaign.campaignName}
                onChange={handleChange}
                name="campaignName"
                type="text"
                placeholder="Give your campaign a unique name"
                className="nameimage"
              />
            </div>
            <div className="sendNowItem">
              <label>
                Select contacts <span className="mandatory">*</span>
              </label>
              {/* <input value={campaign.campaignName} onChange={handleChange} name="campaignName" type="text" placeholder='Give your campaign a unique name' className="nameimage" /> */}
              <Select
                isMulti={campaign.isBulk}
                value={campaign.isBulk ? selectedContactList : selectedContact}
                options={contactList.map((c) => ({
                  label: c.label,
                  value: c.number,
                }))}
                className="basic-multi-select role_selectbox"
                classNamePrefix="select"
                onChange={(e) => {
                  if (campaign.isBulk) {
                    setSelectedContactList(e);
                  } else {
                    setSelectedContact(e);
                    setCampaign((prevCampaign) => ({
                      ...prevCampaign,
                      contactNumber: e.value,
                    }));
                  }
                }}
              />
            </div>

            <div className="sendNowItemContainer">
              {campaign.isBulk === true ? (
                <div className="scheduleItemFile">
                  <label>
                    Add SMS Contacts <span className="mandatory">*</span>
                  </label>
                  <input
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    type="file"
                  />
                  <span className="scheduleItemFileText">
                    Only .csv format allowed.
                    {campaign.sendThrough === SEND_THROUGH_SMS_VALUE ? (
                      <a href="/SampleCSVFile.csv" download>
                        Download sample .csv file here
                      </a>
                    ) : (
                      <a href="/SampleEmailCSVFile.csv" download>
                        Download sample .csv file here
                      </a>
                    )}
                  </span>
                </div>
              ) : campaign.sendThrough === SEND_THROUGH_SMS_VALUE ? (
                <div className="sendNowItem shortwidth">
                  <label>
                    Add Mobile Number <span className="mandatory">*</span>
                  </label>
                  <input
                    value={campaign.contactNumber}
                    onChange={(e) => {
                      handleChange(e);
                      setSelectedContact(null);
                    }}
                    name="contactNumber"
                    type="text"
                    placeholder="E.I64 format, example: +447123456789"
                    className="contactsimage"
                  />
                </div>
              ) : (
                <div className="sendNowItem shortwidth">
                  <label>
                    Add Email <span className="mandatory">*</span>
                  </label>
                  <input
                    value={campaign.email}
                    onChange={handleChange}
                    name="email"
                    type="text"
                    placeholder="Enter an email"
                  />
                </div>
              )}

              <div className="sendNowItem shortwidth">
                <label>
                  {campaign.sendThrough === SEND_THROUGH_SMS_VALUE
                    ? "Send Bulk SMS Messages"
                    : "Upload Contacts"}
                </label>
                <Switch
                  checked={campaign.isBulk}
                  onChange={handleChangeSwitch}
                  name="isBulk"
                />
              </div>

              {campaign.isBulk === true &&
              campaign.sendThrough === SEND_THROUGH_EMAIL_VALUE ? (
                <div className="sendNowItem">
                  <label>
                    Choose Greetings <span className="mandatory">*</span>
                  </label>
                  <select
                    value={campaign.greetingText}
                    onChange={handleChange}
                    name="greetingText"
                    className="popupDropdown"
                  >
                    <option value="" disabled>
                      Select Greeting
                    </option>
                    {greetings.map((greet) => (
                      <option key={greet.value} value={greet.value}>
                        {greet.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="sendNowItem">
              <div className="templateButtonContainer">
                <label>
                  Enter SMS Message Body Text{" "}
                  <span className="mandatory">*</span>
                </label>
              </div>
              {campaign.sendThrough === SEND_THROUGH_SMS_VALUE ? (
                <textarea
                  value={campaign.messageBody}
                  onChange={handleChange}
                  name="messageBody"
                  placeholder="Enter the body of your SMS message here"
                ></textarea>
              ) : (
                {
                  /*<CKEditor
                  editor={DecoupledEditor}
                  config={{
                    extraPlugins: [ 
                      CustomImageUploadAdapter 
                    ],
                  }}
                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                    editor.ui.getEditableElement().parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );

                    window.ckeditor = editor;
                  }}
                  onError={(error, { willEditorRestart }) => {
                    console.log('Editor error!', error);
                    if (willEditorRestart) {
                      window.ckeditor.ui.view.toolbar.element.remove();
                    }
                  }}
                  data={campaign.messageBody}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChange({ target: { name: 'messageBody', value: data } })
                  }}
                />*/
                }
              )}
              <div className="templateButtonContainer">
                <button
                  className="templateElementButton"
                  onClick={openPopup}
                  style={{ marginTop: "30px" }}
                >
                  <img src={templatesIcon} className="filter-red" />
                  <span className="templateButtonText">Select Template</span>
                </button>
                <button
                  disabled={sending}
                  className="sendNowButton"
                  onClick={handleSubmit}
                >
                  Send Now
                </button>
              </div>
            </div>
          </form>
          <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h3 className="popupTitle">Please select your message template</h3>
            <select
              value={template}
              className="popupDropdown"
              onChange={(event) => setTemplate(event.target.value)}
            >
              <option value="" disabled>
                Select Template
              </option>
              {data.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.templatename}
                </option>
              ))}
            </select>
            <button
              className="sendNowButton"
              disabled={template === ""}
              onClick={applyTemplate}
            >
              Apply
            </button>
          </CustomPopup>
        </>
      )}
    </div>
  );
}
