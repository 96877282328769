import React from "react";
import { useAppState } from "../../state";

export default function LoaderContainer() {
  const { isLoading } = useAppState();

  return (
    <>
      {isLoading && (
        <div className="loading-container">
          <div className="loading"></div>
        </div>
      )}
    </>
  );
}
