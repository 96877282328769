import "./newuser.css";
import React, { useState, useEffect } from "react";
import { ScheduleOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "../../../helpers/request";
// import PasswordChecklist from "react-password-checklist"

import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import Select from "react-select";
import { Breadcrumbs, Typography } from "@material-ui/core";

import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function NewUser() {
  const cookies = new Cookies();
  const user_token = cookies.get("cs_token");
  const userdata = cookies.get("cs_user");

  const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);

  const services = [
    { label: "SMS", value: "sms" },
    { label: "Email", value: "foodSafety" },
    // { label: 'Safety Management', value: 'safetyManagement' },
    // { label: 'Safety Training', value: 'safetyTraining' },
    // { label: 'Remote Working', value: 'remoteWorking' },
    // { label: 'SMS Safety', value:'smsSafety'},
  ];
  const defaultvalue =
    userdata.role === "superadmin"
      ? [services[0]]
      : JSON.parse(userdata.chosenServices);
  const [values, setvalues] = useState(defaultvalue);

  const { user: loggedUser } = useAppState();
  const [userData, setUserData] = useState([]);

  const [adminData, setAdminData] = useState([]);

  const userRole = useParams();
  const history = useHistory();

  useEffect(() => {
    getUserdetails();
  }, []);

  async function getUserdetails() {
    await axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${user_token}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUserData(res.data);
          if (userdata.role !== "superadmin") {
            setUser((prevUser) => ({
              ...prevUser,
              added_by: res?.data[0]?.id,
            }));
          } else {
            setUser((prevUser) => ({
              ...prevUser,
              added_by: null,
            }));
          }

          getAdminUsers();
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getAdminUsers = () => {
    if (userRole.userRole === "ambassador") {
      axios.get("/adminusers").then(async (response) => {
        if (response.status === 200 && response.data) {
          setAdminData(response.data);
        } else {
          throw new Error(response?.error);
        }
      });
    }
  };

  const [user, setUser] = useState({
    fullName: "",
    email: "",
    companyName: "",
    role: userRole.userRole,
    added_by: "",
    chosenServices: values,
  });

  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Super Admin", value: "superadmin" },
    { label: "Ambassador", value: "ambassador" },
  ];

  const filteredRoles = roles.filter((role) => {
    if (loggedUser.role === "superadmin" || role.value !== "superadmin") {
      return role;
    }
  });

  const [adding, setAdding] = useState(false);

  const onchangeselect = (e) => {
    user.chosenServices = e;
    setvalues(e);
  };
  const handleCompany = (e) => {
    const { value } = e.target;
    let data = adminData?.find((list) => list?.id === Number(value));
    if (data) {
      user.chosenServices = JSON.parse(data?.chosenServices);
      user.added_by = data.id;
      user.companyName = data.companyName;
    }
  };

  const handleChange = (event) => {
    console.log(event);
    const { name, value } = event.target;
    // if (name === 'password') {
    //   setPassword(value)
    // }
    // if (name === 'cpassword') {
    //   setPasswordAgain(value)
    // }
    if (userData[0]?.role === "admin") {
      setUser((prevUser) => ({
        ...prevUser,
        companyName: user?.companyName,
      }));
    }
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const isValidData = () => {
    const { fullName, email, cpassword, role, added_by } = user;

    let isValid = true;
    let message = "";

    if (fullName === "" || email === "" || role === "") {
      isValid = false;
      message = "All fields are mandatory";
    } else if (!EMAIL_REGEX.test(email)) {
      isValid = false;
      message = "Please enter valid email address";
    }
    // else if (password !== cpassword) {
    //   isValid = false;
    //   message = "Password and Confirm Password not matched";
    // }

    return { isValid, message };
  };

  const resetForm = () => {
    setUser({
      fullName: "",
      email: "",
      // cpassword: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { isValid, message } = isValidData();
    // if(userData.length > 0){
    // // setUser({
    // //   email:`${userData[0]?.id}`
    // // });
    // // setUser(prevUser => ({
    // //   ...prevUser,
    // //   'added_by':`${userData[0]?.id}`
    // // }));
    // }

    if (isValid) {
      setAdding(true);
      axios
        .post("/user", {
          data: { ...user, phone },
          actionBy: loggedUser.fullName,
          userRoletype: userRole.userRole,
          Usertype: userdata.role,
        })
        .then(function (response) {
          setAdding(false);
          resetForm();
          Swal.fire({
            title: "User added successfully",
            confirmButtonColor: "#3085d6",
          });
          history.push("/users");
        })
        .catch(function (error) {
          setAdding(false);
          // already exists in our records
          if (
            error?.response?.data?.message.email !== "" &&
            error?.response?.data?.message.companyName !== ""
          ) {
            if (
              error?.response?.data?.message.email === user.email &&
              error?.response?.data?.message.companyName === user.companyName
            ) {
              Swal.fire({
                title:
                  ` Email and CompanyName already exists in our records` ||
                  "Unable to process this request",
                confirmButtonColor: "#3085d6",
              });
              return;
            } else if (error?.response?.data?.message.email === user.email) {
              Swal.fire({
                title:
                  ` Email already exists in our records` ||
                  "Unable to process this request",
                confirmButtonColor: "#3085d6",
              });
              return;
            } else {
              Swal.fire({
                title:
                  ` CompanyName already exists in our records ` ||
                  "Unable to process this request",
                confirmButtonColor: "#3085d6",
              });
              return;
            }
          } else {
            Swal.fire({
              title: "Unable to process this request",
              confirmButtonColor: "#3085d6",
            });
          }
        });
    } else {
      Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
    }
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [phone, setPhoneNo] = useState("");

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/users">Users</Link>
        <Typography color="text.primary">Add User</Typography>
      </Breadcrumbs>

      <form className="newUserForm">
        <div className="newUserItem">
          <label>
            Full Name <span className="mandatory">*</span>
          </label>
          <input
            value={user.fullName}
            onChange={handleChange}
            name="fullName"
            type="text"
            placeholder="Enter the users full name"
          />
        </div>
        <div className="newUserItem">
          <label>
            Email Address <span className="mandatory">*</span>
          </label>
          <input
            value={user.email}
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Enter the users email address"
          />
        </div>
        <div className="newUserItem">
          <label>Phone No</label>
          <PhoneInput
            inputStyle={{
              width: "600px",
              height: "50px",
            }}
            country={"ie"}
            value={phone}
            onChange={(data, country) => setPhoneNo(data)}
          />
          {/*  <input
            value={user.email}
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Enter the users email address"
          /> */}
        </div>
        {/* <div className="newUserItem">
          <label>Confirm Password <span className="mandatory">*</span></label>
          <input value={user.cpassword} onChange={handleChange} name="cpassword" type="password" placeholder='Confirm the password' />
        </div> */}

        {userData[0]?.role === "superadmin" &&
        userRole.userRole === "ambassador" ? (
          <div className="newUserItem">
            <label>
              Choose Company<span className="mandatory">*</span>
            </label>
            <select
              // value={user?.role}
              onChange={handleCompany}
              name="added_by"
              className="popupDropdown"
            >
              <option value="Select Company" selected disabled>
                Select Company
              </option>
              {adminData?.map((i, index) => {
                return (
                  <option key={index} value={i.id}>
                    {i?.fullName}- {i?.companyName}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}

        {userData[0]?.role === "superadmin" && userRole.userRole === "admin" ? (
          <div className="newUserItem">
            <label>
              Choose Services <span className="mandatory">*</span>
            </label>
            <Select
              defaultValue={values}
              value={values}
              isMulti
              name="chosenServices"
              options={services}
              className="basic-multi-select role_selectbox"
              classNamePrefix="select"
              onChange={onchangeselect}
            />
          </div>
        ) : null}
        {userData[0]?.role === "superadmin" && userRole.userRole === "admin" ? (
          <div className="newUserItem">
            <label>
              Company Name <span className="mandatory">*</span>
            </label>
            <input
              value={user.companyName}
              onChange={handleChange}
              name="companyName"
              type="text"
              placeholder="Enter the Company Name"
            />
          </div>
        ) : null}

        <div className="newUserItem">
          <label>
            Choose Role <span className="mandatory">*</span>
          </label>
          <select
            value={user?.role}
            disabled
            onChange={handleChange}
            name="role"
            className="popupDropdown"
          >
            {/* <option value={user?.role}>{userRole?.userRole}</option> */}
            {filteredRoles
              .filter((r) => {
                if (r.value === userRole.userRole) {
                  return r;
                }
              })
              .map((role) => {
                return (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                );
              })}
          </select>
        </div>
        {/* <div className="newUserItem">
          <label>Password <span className="mandatory">*</span></label>
          <input value={user.password} onChange={handleChange} name="password" type="password" placeholder='Enter a password for the user' />
        </div> */}

        <div className="newUserItem">
          {/* <label>Confirm Password <span className="mandatory">*</span></label>
          <input value={user.cpassword} onChange={handleChange} name="cpassword" type="password" placeholder='Confirm the password' />
          <div className="passwordContainer">
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => { }} />
          </div> */}
          <button
            disabled={adding}
            onClick={handleSubmit}
            className="newUserButton"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}
