import React from "react";
import { useEffect } from "react";
import { useAppState } from "../../../state";
import "./chat.css";
import axios from "../../../helpers/request";
import { useState } from "react";
import { SocketContext } from "../../../socket";
import { useContext } from "react";
import WhatsappSMSChannel from "../Whatsapp-Channel/WhatsappSMSChannel";
import CustomPopup from "../../Popup/CustomPopup";
import { useLocation } from "react-router-dom";

export default function WpUI() {
  const socket = useContext(SocketContext);
  const { user } = useAppState();
  const location = useLocation();
  const [chats, setChats] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [message, setMessage] = useState("");
  const [convId, setConversationId] = useState("");
  const [chatId, setChatId] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const isArchivePage = location.pathname.includes("archive");
  const getChatList = () => {
    setButtonPopup(false);
    axios
      .get(
        `/${!isArchivePage ? "get-chats" : "get-archives"}/${user.added_by ? user.added_by : user.id}`,
      )
      .then(async (response) => {
        if (response.status === 200 && response.data && response.data.length) {
          console.log(response.data);
          let list = response.data.filter((v) =>
            v.campaignid.includes("-sends-"),
          );
          setChats(list);
          setConversationId(list[0].campaignid);
          setChatId(list[0].id);
        } else {
          throw new Error(response?.error);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getConversation = (conversationId) => {
    console.log("new message");
    axios
      .get(`/get-chat-messages/${conversationId}`)
      .then(async (response) => {
        if (response.status === 200 && response.data && response.data.length) {
          console.log(response.data);
          setConversation(response.data);
          setCurrentChannel(
            channelList.filter(
              (v) => v.fromdata == conversationId.split("-sends-")[0],
            )[0],
          );
        } else {
          throw new Error(response?.error);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    var conversationDiv = document.getElementById("chat");
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }, [conversation]);

  useEffect(() => {
    socket.on("new_message", () => {
      getConversation(convId);
    });
    if (convId) {
      socket.emit("notification_register", {
        customer: convId.split("-sends-")[1],
        channel: convId.split("-sends-")[0],
        user: user.id,
      });
      getConversation(convId);
    }
    return () => socket.off("new_message");
  }, [convId]);

  const sendMessage = () => {
    setLoading(true);
    axios
      .post(`/send-outgoing-wp-message`, {
        contactNumber: convId.split("-sends-")[1],
        messageBody: message,
        actionBy: user.fullName,
        ...currentChannel,
      })
      .then(async (response) => {
        if (response.status === 200 && response.data) {
          console.log(response.data);
          getConversation(convId);
          setMessage("");
        } else {
          throw new Error(response?.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  const markUnmarkArchived = () => {
    setLoading(true);
    axios
      .put(`${!isArchivePage ? "/mark-archived" : "/mark-inboxed"}`, {
        campaignid: convId,
      })
      .then(async (response) => {
        if (response.status === 200 && response.data) {
          getChatList();
          setConversation([]);
          setConversationId("");
          setMessage("");
        } else {
          throw new Error(response?.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  const getWhatsappChannels = () => {
    axios
      .get(`/wp-channels/${user.added_by ? user.added_by : user.id}`)
      .then(async (response) => {
        if (response.status === 200 && response.data) {
          console.log(response.data);
          setChannelList(response.data);
        } else {
          throw new Error(response?.error);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    getChatList();
    getWhatsappChannels();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && message && convId) {
      sendMessage();
    }
  };
  return (
    <div>
      <div
        class="row rounded-lg overflow-hidden"
        style={{
          minHeight: "calc(100vh - 85px)",
          width: "100%",
          paddingTop: "5px",
        }}
      >
        <div
          class="col-3 px-0 light-border"
          style={{ minHeight: "calc(100vh - 85px)" }}
        >
          <div class="bg-white" style={{ minHeight: "calc(100vh - 85px)" }}>
            <div class="px-4 py-2 bg-light d-flex justify-content-between">
              <p class="h5 mb-0 py-1">Conversations</p>
              {!isArchivePage && (
                <button
                  className="btn"
                  onClick={() => setButtonPopup(true)}
                  style={{ minWidth: "50px" }}
                >
                  <i class="fa-solid fa-user-plus"></i>
                </button>
              )}
            </div>
            <div class="messages-box">
              <div class="list-group rounded-0">
                {chats.map((chat) => {
                  let chatData = chat.campaignid.split("-sends-");
                  return (
                    <a
                      class={`list-group-item list-group-item-action ${chat.id === chatId ? "act-chat text-white" : ""} rounded-0`}
                      onClick={() => {
                        setChatId(chat.id);
                        setConversationId(chat.campaignid);
                      }}
                    >
                      <div class="media conv">
                        <i
                          class="fa-solid fa-circle-user rounded-circle"
                          style={{
                            fontSize: "45px",
                            width: "15%",
                            color:
                              chat.id === chatId
                                ? "white"
                                : "rgba(0, 0, 0, 0.4)",
                          }}
                        ></i>
                        <div
                          class="media-body d-flex justify-content-between"
                          style={{ width: "85%" }}
                        >
                          <div class="d-flex align-items-start justify-content-between mb-1 flex-column">
                            <h6 class="mb-0">{chatData[0]}</h6>
                            <p class="mb-0">{chatData[1]}</p>
                          </div>
                          <div class="d-flex align-items-start justify-content-between mb-1 flex-column">
                            <small class="small font-weight-bold">
                              {new Date(chat.lastmessagetime)
                                .toUTCString()
                                .substr(0, 11)}
                            </small>
                            <small class="small font-weight-bold align-self-end">
                              <i
                                class={`fa-solid fa-arrow-${chat.lastmessagetype !== 1 ? "up" : "down"}`}
                              ></i>
                            </small>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 px-0">
          <div
            class="px-4 py-5 chat-box bg-white"
            style={{
              minHeight: "calc(100vh - 155px)",
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            id="chat"
          >
            {conversation.map((message) =>
              message.type == 1 ? (
                <div class="media w-50 mb-3 d-flex">
                  <i
                    class="fa-solid fa-circle-user rounded-circle pe-2"
                    style={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.4)" }}
                  ></i>
                  <div class="media-body ml-3">
                    <div class="bg-light rounded py-2 px-3 mb-2">
                      <p class="text-small mb-0 text-muted">
                        {message.messagebody}
                      </p>
                    </div>
                    <p class="small text-muted">
                      {new Date(message.messagetime)
                        .toUTCString()
                        .substr(0, 22)}
                    </p>
                  </div>
                </div>
              ) : (
                <div class="media w-50 ms-auto mb-3 d-flex justify-content-end">
                  <div class="media-body">
                    <div class="bg-theme-color rounded py-2 px-3 mb-2 position-relative">
                      <p class="text-small mb-0 text-white">
                        {message.messagebody}
                      </p>
                      {message.status == "read" ? (
                        <i
                          class="fa-solid fa-check-double position-absolute text-white"
                          style={{
                            right: "4px",
                            bottom: "4px",
                            fontSize: "12px",
                          }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-check position-absolute text-white"
                          style={{
                            right: "4px",
                            bottom: "4px",
                            fontSize: "12px",
                          }}
                        ></i>
                      )}
                    </div>
                    <p class="small text-muted">{message.messagetime}</p>
                  </div>
                  <i
                    class="fa-solid fa-user-tie rounded-circle ps-2"
                    style={{ fontSize: "30px", color: "rgba(0, 0, 0, 0.4)" }}
                  ></i>
                </div>
              ),
            )}
          </div>
          <form class="bg-light">
            <div class="input-group">
              <div class="input-group-append d-flex align-items-center">
                <button
                  id="button-addon2"
                  class="btn btn-link"
                  style={{ minWidth: "30px" }}
                  disabled={!convId || loading}
                  title={isArchivePage ? "Remove Archive" : "Add to Archive"}
                  onClick={(e) => {
                    e.preventDefault();
                    markUnmarkArchived();
                  }}
                >
                  {/* <i class="fa-solid fa-paper-plane"></i> */}
                  {isArchivePage ? (
                    <i class="fa-solid fa-inbox"></i>
                  ) : (
                    <i class="fa-solid fa-box-archive"></i>
                  )}
                </button>
              </div>
              {/* <div class="input-group-append">
								<button id="button-addon2" class="btn btn-link" style={{ minWidth: "30px" }} disabled={!convId || loading} onClick={e => {
									e.preventDefault()
									if (convId && message) {
										sendMessage()
									}
								}}> <i class="fa-solid fa-paper-plane"></i></button>
							</div>
							<div class="input-group-append">
								<button id="button-addon2" class="btn btn-link" style={{ minWidth: "30px" }} disabled={!convId || loading} onClick={e => {
									e.preventDefault()
									if (convId && message) {
										sendMessage()
									}
								}}> <i class="fa-solid fa-paper-plane"></i></button>
							</div> */}
              <input
                type="text"
                placeholder="Type a message"
                aria-describedby="button-addon2"
                class="form-control rounded-0 border-0 py-4 bg-light"
                disabled={!convId || loading}
                value={message}
                onKeyDown={handleKeyPress}
                onChange={(e) => {
                  console.log(e.keycode);
                  setMessage(e.target.value);
                }}
              />
              <div class="input-group-append">
                <button
                  id="button-addon2"
                  class="btn btn-link"
                  style={{
                    minWidth: "60px",
                    height: "100%",
                  }}
                  disabled={!convId || loading}
                  onClick={(e) => {
                    e.preventDefault();
                    if (convId && message) {
                      sendMessage();
                    }
                  }}
                >
                  {" "}
                  <i
                    class="fa-solid fa-paper-plane"
                    style={{ fontSize: "25px" }}
                  ></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <WhatsappSMSChannel updateChatList={() => getChatList()} />
      </CustomPopup>
    </div>
  );
}
