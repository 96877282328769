import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SecurityOutlined } from "@material-ui/icons";
import axios from "axios";
import PasswordChecklist from "react-password-checklist";
import Swal from "sweetalert2";

import "./passwordreset.css";
import logo from "../images/ProntobyKaptea.svg";
import graphic from "../images/logingraphic2023.svg";

export default function PasswordReset() {
  // const location = useLocation();
  const history = useHistory();

  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (history.location.state && history.location.state.token) {
      let state = { ...history.location.state };
      setToken(state.token);
      delete state.token;
      history.replace({ ...history.location, state });
    } else {
      history.push("/");
    }
  }, [history]);

  const isValidData = () => {
    let isValid = true;
    let message = "";

    if (newPassword === "" || confirmPassword === "") {
      isValid = false;
      message = "All fields are mandatory";
    } else if (newPassword !== confirmPassword) {
      isValid = false;
      message = "Password and Confirm Password are not matched";
    }

    return { isValid, message };
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    if (token) {
      const { isValid, message } = isValidData();
      if (isValid) {
        setUpdating(true);
        axios
          .post(process.env.REACT_APP_API_BASEURL + "/reset-password", {
            token: token,
            password: confirmPassword,
          })
          .then(async function (response) {
            setUpdating(false);
            await Swal.fire({
              title: "Reset password successfully",
              confirmButtonColor: "#3085d6",
            });
            history.replace("/");
          })
          .catch(async function (error) {
            setUpdating(false);
            await Swal.fire({
              title: "Unable to process reset password",
              confirmButtonColor: "#3085d6",
            });
          });
      } else {
        await Swal.fire({ title: message, confirmButtonColor: "#3085d6" });
      }
    } else {
      await Swal.fire({
        title: "Unable to verify details, Please retry with new otp",
        confirmButtonColor: "#3085d6",
      });
      history.replace("/");
    }
  };

  return (
    <div className="" style={{ display: "flex" }}>
      <div className="passwordReset">
        <span className="reset-password-img">
          <img
            src={logo}
            alt=""
            className="logo"
            style={{ maxWidth: "280px" }}
          />
          <img
            src={graphic}
            className="logo-two"
            style={{ paddingRight: "65px", paddingLeft: "65px" }}
            alt=""
          />
        </span>
        {/* <div className="pageHeader">
        <SecurityOutlined className="pageHeaderIcon" />
        <div className="pageHeaderText">
          <span className="pageHeaderTitle">Reset your password</span>
          <span className="pageHeaderDescription">Reset your password.</span>
        </div>
      </div> */}
        <div className="reset-password-form">
          <form className="passwordResetForm">
            <div className="reset-password-title">
              <span>Reset Password</span>
            </div>
            <div className="passwordResetItem">
              <label>Password</label>
              <input
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                type="password"
                placeholder="Enter a password for the user"
              />
            </div>
            <div className="passwordResetItem">
              <label>Confirm Password</label>
              <input
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                type="password"
                placeholder="Confirm the password"
              />

              <div className="passwordContainer">
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={newPassword}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => {}}
                />
              </div>

              <button
                disabled={updating}
                onClick={resetPassword}
                className="passwordResetButton"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
